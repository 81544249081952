import dayjs, { Dayjs } from 'dayjs';

import { i18nService } from '@/app/i18n/i18n.service';

type AcceptableDateObject = Dayjs | string | number | null;

const dateParseFormatISO = 'YYYY-MM-DD';
const dateParseFormatsEU = ['DDMM.YYYY', 'DD.MM.YYYY', 'D.M.YYYY', 'DD.MM.YY', 'D.M.YY'];
const dateParseFormatsUS = ['MM/DD/YYYY'];

function parseDateString(dateString: string) {
  const locale = i18nService.vueI18n.global.locale as string;
  const dateParseFormats = locale.includes('US') ? dateParseFormatsUS : dateParseFormatsEU;
  dateParseFormats.unshift(dateParseFormatISO);
  return dayjs(dateString, dateParseFormats);
}

export function isValidDateString(dateString: string) {
  return parseDateString(dateString).isValid();
}

export function parseToISODateString(dateString: string) {
  return parseDateString(dateString).format('YYYY-MM-DD');
}

export function formatToLocale(date: AcceptableDateObject, format = 'L', locale = undefined) {
  // 'de' locale formats:

  // L: 31.01.2024 (default)
  // l: 31.1.2024

  // LL: 31. Januar 2024
  // ll: 31. Jan. 2024

  // LLL: 31. Januar 2024 13:00
  // lll: 31. Jan. 2024 12:00

  // LLLL: Mittwoch, 31. Januar 2024 13:00
  // llll: Mi., 31. Jan. 2024 13:00

  // LT: 13:00
  // LTS: 13:00:00

  const dateObj = dayjs(date);
  if (locale) {
    dateObj.locale(locale);
  }
  return dateObj.format(format);
}

export function formatToHumanDuration(date: AcceptableDateObject, locale = undefined) {
  const dateObj = dayjs(date);
  if (locale) {
    dateObj.locale(locale);
  }
  return dateObj.fromNow();
}
