import axios from 'axios';
import dayjs from 'dayjs';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import { websocketService } from '@/app/services/websocket.service';
import { LegalCase, legalCaseService } from '@/case-list/services/legalcase.service';
import { authService } from '@/common/services/auth/auth.service';
import { ISODateTimeString, ObjectValues, UUID } from '@/common/types/common.types';

// TransferTaskProgress.java
export interface TransferTaskProgress {
  taskId: string;
  legalCaseId: UUID;
  progress: number;
  filesTransferred: number;
  totalFiles: number;
  state: string;
  transferType: 'EXPORT' | 'IMPORT';
}

// TransferDTO.java
export interface TransferDTO {
  legalCaseIds: UUID[];
}

const TRANSFER_STATE = {
  INITIATED: 'INITIATED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
} as const;
export type TransferState = ObjectValues<typeof TRANSFER_STATE>;

export interface TransferMetadata {
  ts: ISODateTimeString;
  transferId: UUID;
  legalCaseId: UUID;
  srcBucket: string;
  dstBucket: string;
  errorMessage: string;
  state: TransferState;
  fileCount: number;
}

// TransferCaseInfo.java
export interface TransferCaseInfo {
  id: UUID;
  displayLabel: string;
  metadata: TransferMetadata | null;
  taskProgress: TransferTaskProgress | null;
  selectable: boolean | null;
}

interface ServiceState {
  cases: TransferCaseInfo[];
  inProgressImports: TransferCaseInfo[];
  isImportInProgress: boolean;
}

class TransferService {
  state: ServiceState;

  constructor() {
    this.state = reactive({
      cases: [],
      inProgressImports: [],
      isImportInProgress: false,
    });
  }

  async init() {
    this.subscribeToWebSocketEvents();
    await legalCaseService.load();

    const { cases } = legalCaseService.state;
    const toExport = this.mapLegalCaseToTransferInfo(cases);
    const toImport = await this.listTransferCases();
    if (!toImport || toImport.length === 0) {
      this.state.cases = toExport;
    } else {
      this.state.cases = await this.combineImportAndExportCandidates(toImport, toExport);
      this.state.inProgressImports = this.state.cases.filter((c) => c.taskProgress?.transferType === 'IMPORT');
    }
    appService.setLoading(false);
  }

  async exportLegalCase(transferDto: TransferDTO) {
    axios
      .post(config.API.TRANSFER_ENDPOINT.EXPORT, transferDto)
      .then((response) => {
        if (response.status === 102) {
          appService.info($t('CaseList.transferInProcessing'));
        }
      })
      .catch((error) => handleError($t('CaseList.exportError'), error));
  }

  async importLegalCase(transferDto: TransferDTO) {
    this.state.isImportInProgress = true;
    axios
      .post(config.API.TRANSFER_ENDPOINT.IMPORT, transferDto)
      .then((response) => {
        if (response.status === 102) {
          appService.info($t('CaseList.transferInProcessing'));
        }
      })
      .catch((error) => handleError($t('CaseList.importError'), error));
  }

  async listTransferCases() {
    appService.setLoading(true);
    try {
      const response = await axios.get(config.API.TRANSFER_ENDPOINT.LIST);
      const toImport = response.data as TransferCaseInfo[];
      toImport.forEach((c) => {
        c.selectable = true;
      });
      return toImport;
    } catch (error) {
      handleError($t('CaseList.casesCannotBeLoaded'), error);
      return [];
    } finally {
      appService.setLoading(false);
    }
  }

  async deleteTransfers(transferDto: TransferDTO) {
    appService.setLoading(true);
    axios
      .delete(config.API.TRANSFER_ENDPOINT.BASE, { data: transferDto })
      .then(() => {
        this.state.cases = this.state.cases.map((c) => {
          if (transferDto.legalCaseIds.includes(c.id)) {
            c.taskProgress = null;
            c.metadata = null;
          }
          return c;
        });
      })
      .finally(() => appService.setLoading(false));
  }

  async refresh() {
    await legalCaseService.load();
    const { cases } = legalCaseService.state;
    const toExport = this.mapLegalCaseToTransferInfo(cases);
    const toImport = await this.listTransferCases();
    this.state.cases = await this.combineImportAndExportCandidates(toImport, toExport);
    this.state.inProgressImports = this.state.cases.filter((c) => c.taskProgress?.transferType === 'IMPORT');
  }

  async loadTaskProgress() {
    const response = await axios.get(`${config.API.TRANSFER_ENDPOINT.PROGRESS}`);
    return response.data as TransferTaskProgress[];
  }

  async subscribeToWebSocketEvents() {
    const tenantId = authService.state.data?.tenant.id;

    // subscribe to initiated
    websocketService.subscribeToTopic(`/topic/${tenantId}/transfer-initiated`, (message) => {
      const parsedMessage = JSON.parse(message.body) as TransferCaseInfo;

      if (parsedMessage.taskProgress?.transferType === 'IMPORT') {
        this.state.inProgressImports.push(parsedMessage);
        return;
      }

      const { id } = parsedMessage;
      this.state.cases = this.state.cases.map((c) => {
        if (c.id === id) {
          const { displayLabel } = c;
          parsedMessage.displayLabel = displayLabel;
          c.taskProgress = parsedMessage.taskProgress;
          c.metadata = parsedMessage.metadata;
        }
        return c;
      });
    });

    // subscribe to progress
    websocketService.subscribeToTopic(`/topic/${tenantId}/transfer-progress`, (message) => {
      const parsedMessage = JSON.parse(message.body) as TransferTaskProgress;

      if (parsedMessage?.transferType === 'IMPORT') {
        this.state.inProgressImports = this.state.inProgressImports.map((c) => {
          if (c.id === parsedMessage.legalCaseId) {
            c.taskProgress = parsedMessage;
          }
          return c;
        });
        return;
      }

      const { legalCaseId } = parsedMessage;
      this.state.cases = this.state.cases.map((c) => {
        if (c.id === legalCaseId) {
          c.taskProgress = parsedMessage;
          c.metadata = {
            ts: dayjs().toISOString(),
            transferId: parsedMessage.taskId,
            legalCaseId: parsedMessage.legalCaseId,
            srcBucket: c.metadata?.srcBucket || 'source-bucket',
            dstBucket: c.metadata?.dstBucket || 'destination-bucket',
            errorMessage: '',
            state: parsedMessage.state as TransferState,
            fileCount: parsedMessage.totalFiles,
          };
        }
        return c;
      });
    });

    // subscribe to completed
    websocketService.subscribeToTopic(`/topic/${tenantId}/transfer-completed`, (message) => {
      const parsedMessage = JSON.parse(message.body) as TransferCaseInfo;

      if (parsedMessage.taskProgress?.transferType === 'IMPORT') {
        this.state.inProgressImports = this.state.inProgressImports.map((c) => {
          if (c.id === parsedMessage.id) {
            c.taskProgress = parsedMessage.taskProgress;
            c.metadata = parsedMessage.metadata;
          }
          return c;
        });

        if (this.state.inProgressImports.every((c) => c.taskProgress?.state !== 'IN_PROGRESS' && c.taskProgress?.state !== 'INITIATED')) {
          this.state.isImportInProgress = false;
        }
      }

      const { id } = parsedMessage;
      this.state.cases = this.state.cases.map((c) => {
        if (c.id === id) {
          const { displayLabel } = c;
          parsedMessage.displayLabel = displayLabel;
          c.taskProgress = parsedMessage.taskProgress;
          c.metadata = parsedMessage.metadata;
        }
        return c;
      });
    });
  }

  mapLegalCaseToTransferInfo(cases: LegalCase[]): TransferCaseInfo[] {
    const toExport = cases.map((lc) => ({
      id: lc.id,
      displayLabel: lc.displayLabel,
      metadata: null,
      taskProgress: null,
      selectable: !lc.inProgress,
    }));
    return toExport;
  }

  isInProgress() {
    return this.state.cases.some((c) => c.taskProgress?.state === 'IN_PROGRESS') || this.state.isImportInProgress;
  }

  unsubscribeFromWebSocketEvents() {
    websocketService.unsubscribeFromTopic(`/topic/${authService.state.data?.tenant.id}/transfer-initiated`);
    websocketService.unsubscribeFromTopic(`/topic/${authService.state.data?.tenant.id}/transfer-progress`);
    websocketService.unsubscribeFromTopic(`/topic/${authService.state.data?.tenant.id}/transfer-completed`);
  }

  async combineImportAndExportCandidates(toImport: TransferCaseInfo[], toExport: TransferCaseInfo[]) {
    const combinedCases = [...toImport];

    toExport.forEach((exportCase) => {
      const importCase = toImport.find((ic: TransferCaseInfo) => ic.id === exportCase.id);
      if (!importCase) {
        combinedCases.push(exportCase);
      } else {
        importCase.selectable = true;
      }
    });

    const progress = await this.loadTaskProgress();
    // map in any cached task progress
    if (progress && progress.length > 0) {
      const progressMap = new Map(progress.map((ctp) => [ctp.legalCaseId, ctp]));

      const test = combinedCases.map((c) => {
        const taskProgress = progressMap.get(c.id);
        return taskProgress ? { ...c, taskProgress } : c;
      });
      return test;
    }

    return combinedCases;
  }
}

export default new TransferService();
