import { UI, WebViewerInstance } from '@pdftron/webviewer';
import axios from 'axios';

import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import { docPilotService } from '@/case-detail/subviews/copilot/services/copilot.service';
import userAnnotationService from '@/case-detail/subviews/document/annotations/services/user.annotation.service';
import documentService, { Document } from '@/case-detail/subviews/document/services/document.service';
import { pdftronHelper, ViewMode } from '@/case-detail/subviews/document/services/pdftron.helper';
import viewerService from '@/case-detail/subviews/document/services/viewer.service';
import duplicatesReviewService from '@/case-detail/subviews/duplicates/services/duplicates.review.service';
import { sourceFileClient } from '@/common/clients/sourcefile.client';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';

interface VueComponentInstance {
  document: Document;
}

interface State {
  element: HTMLElement;
  webViewer: WebViewerInstance;
  docViewer: WebViewerInstance['Core']['documentViewer'];
  vueInstance: VueComponentInstance;
  showSplitBtn: boolean;
}

const pdftronUiHelper = {
  state: undefined as undefined | State,
  isLoaded: false,

  setInstance(wv: WebViewerInstance, el: HTMLElement, vi: VueComponentInstance) {
    this.state = {
      webViewer: wv,
      element: el,
      docViewer: wv.Core.documentViewer,
      vueInstance: vi,
      showSplitBtn: false,
    };
  },

  clear() {
    this.state = undefined;
    this.isLoaded = false;
  },

  getLeftSideHeaderButtons() {
    return [];
  },

  getRightSideHeaderButtons(privilegedUser: boolean, workspaceAdmin = false, externalUser = false) {
    const { state } = this;
    if (!state) {
      return null;
    }

    const splitButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">  <path fill="currentColor" d="M11,21H7V19H11V21M15.5,19H17V21H13V19H13.2L11.8,12.9L9.3,13.5C9.2,14 9,14.4 8.8,14.8C7.9,16.3 6,16.7 4.5,15.8C3,14.9 2.6,13 3.5,11.5C4.4,10 6.3,9.6 7.8,10.5C8.2,10.7 8.5,11.1 8.7,11.4L11.2,10.8L10.6,8.3C10.2,8.2 9.8,8 9.4,7.8C8,6.9 7.5,5 8.4,3.5C9.3,2 11.2,1.6 12.7,2.5C14.2,3.4 14.6,5.3 13.7,6.8C13.5,7.2 13.1,7.5 12.8,7.7L15.5,19M7,11.8C6.3,11.3 5.3,11.6 4.8,12.3C4.3,13 4.6,14 5.3,14.4C6,14.9 7,14.7 7.5,13.9C7.9,13.2 7.7,12.2 7,11.8M12.4,6C12.9,5.3 12.6,4.3 11.9,3.8C11.2,3.3 10.2,3.6 9.7,4.3C9.3,5 9.5,6 10.3,6.5C11,6.9 12,6.7 12.4,6M12.8,11.3C12.6,11.2 12.4,11.2 12.3,11.4C12.2,11.6 12.2,11.8 12.4,11.9C12.6,12 12.8,12 12.9,11.8C13.1,11.6 13,11.4 12.8,11.3M21,8.5L14.5,10L15,12.2L22.5,10.4L23,9.7L21,8.5M23,19H19V21H23V19M5,19H1V21H5V19Z" /></svg>',
      title: $t('CaseDetail.Document.splitDocumentHere'),
      dataElement: 'splitButton',
      onClick: () => {
        $a.l($a.e.DOC_SPLIT);
        documentService.splitDocument(state.vueInstance.document, state.vueInstance.document.sourceFilePage + state.docViewer.getCurrentPage() - 1);
      },
    };

    // NOTe(dp): copied from pdftron default header items
    const searchButton = {
      type: 'toggleElementButton',
      dataElement: 'searchButton',
      element: 'searchPanel',
      img: 'icon-header-search',
      title: 'component.searchPanel',
      hidden: ['small-mobile'],
    };

    // Download button
    const downloadButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path class="cls-1" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" /></svg>',
      onClick: () => {
        $a.l($a.e.DOC_DOWNLOAD);
        const filename = `${detailViewService.getCurrentLegalCase()?.displayLabel.replace(' ', '-')}-${state.vueInstance.document.metadata.REF.value}.pdf`;
        state.webViewer.UI.downloadPdf({ filename, includeAnnotations: false });
      },
      title: $t('CaseDetail.Export.download'),
      dataElement: 'customDownloadButton',
    };

    const hocrDownloadButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path class="cls-1" d="M9,7H11V11H13V7H15V17H13V13H11V17H9V7M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M5,5V19H19V5H5Z" /></svg>',
      onClick: async () => {
        appService.info($t('Common.File.preparingDownload'));
        const page = state.vueInstance.document.sourceFilePage + state.docViewer.getCurrentPage() - 1;
        const response = await axios.get(
          config.API.INTERNAL.HOCR_EXPORT.replace('{legalCaseId}', state.vueInstance.document.caseId)
            .replace('{sourceFileId}', state.vueInstance.document.sourceFileId)
            .replace('{page}', page.toString()),
        );

        const blob = new Blob([response.data], {
          type: 'text/plain',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `hocr_${state.vueInstance.document.sourceFileId}_${page}.txt`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      title: $t('CaseDetail.Document.downloadHOCR'),
      dataElement: 'customHocrDownloadButton',
    };

    const googleDocumentAiDownloadButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>alpha-d-box-outline</title><path d="M9,7H13A2,2 0 0,1 15,9V15A2,2 0 0,1 13,17H9V7M11,9V15H13V9H11M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M5,5V19H19V5H5Z" /></svg>',
      onClick: async () => {
        const sourceFile = await sourceFileClient.get(state.vueInstance.document.caseId, state.vueInstance.document.sourceFileId);
        if (!sourceFile.extractorVersions.BASE_OCR.includes('legali_ocrworkerai')) {
          appService.error($t('CaseDetail.Document.documentWasnotProcessViaGoogleAI'));
          return;
        }
        appService.info($t('Common.File.preparingDownload'));
        const page = state.vueInstance.document.sourceFilePage + state.docViewer.getCurrentPage() - 1;
        const response = await axios.get(
          config.API.INTERNAL.GOOGLE_DOCUMENTAI_EXPORT.replace('{legalCaseId}', state.vueInstance.document.caseId)
            .replace('{sourceFileId}', state.vueInstance.document.sourceFileId)
            .replace('{page}', page.toString()),
        );
        const blob = new Blob([JSON.stringify(response.data, null, 2)], {
          type: 'application/json',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `documentai_${state.vueInstance.document.sourceFileId}_${page}.json`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      title: $t('CaseDetail.Document.downloadGoogleAiJson'),
      dataElement: 'customGoogleDocumentAiDownloadButton',
    };

    // Print button
    const printButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path class="cls-1" d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z" /> </svg>',
      onClick: () => {
        state.webViewer.UI.print();
        $a.l($a.e.DOC_PRINT);
      },
      title: $t('Common.print'),
      dataElement: 'customPrintButton',
    };

    // Back to duplicate review button
    const backToDuplicateReviewButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>',
      title: $t('CaseDetail.Document.closeComparison'),
      dataElement: 'backToDuplicatesReviewButton',
      onClick: async () => {
        pdftronHelper.switchMode(ViewMode.Normal);

        if (duplicatesReviewService.state.originalDocument) {
          detailViewService.openPanel('DuplicatesReview');
        } else {
          // @ts-expect-error incompatibility with JS
          viewerService.resetWebViewer({ document: documentService.getSelected() });
        }
      },
    };

    // Free comment button
    const addFreeCommentButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>comment-text-outline</title><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z" /></svg>',
      title: $t('CaseDetail.Document.addComment'),
      dataElement: 'addFreeCommentButton',
      onClick: async () => {
        userAnnotationService.addTemporaryComment('USER_COMMENT_FREE');
        $a.l($a.e.DOC_COMMENT_FREE);
      },
    };

    const closeViewButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close</title><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>',
      onClick: async () => {
        // close search panel first, then whole DocView (otherwise unexpected UX for some users)
        const isSearchPanelOpen = () => {
          const iframeDoc = state.webViewer.UI.iframeWindow.document;
          const searchPanel = iframeDoc.querySelector('[data-element="searchPanel"]');
          return !!searchPanel && !searchPanel.classList.contains('closed');
        };
        if (isSearchPanelOpen()) {
          state.webViewer.UI.closeElements(['searchPanel']);
          return;
        }

        broadcastEventBus.emit('PANEL_CLOSE_EVENT', { panel: 'WebViewer' });
        // allow listeners to do smth with state before close
        setTimeout(() => detailViewService.closePanel('WebViewer'), 10);
      },
      title: $t('Common.close'),
      dataElement: 'closeDocumentViewButton',
    };

    const buttons: ReturnType<UI.Header['getItems']> = [...(!externalUser ? [splitButton, addFreeCommentButton, backToDuplicateReviewButton] : [])];

    if (privilegedUser || workspaceAdmin || authService.hasFeature('ENABLE_DOCVIEW_PRINT_DOWNLOAD')) {
      buttons.push(printButton);
      buttons.push(downloadButton);
    }

    if (privilegedUser) {
      buttons.push(hocrDownloadButton);
      buttons.push(googleDocumentAiDownloadButton);
    }

    buttons.push(searchButton);
    buttons.push(closeViewButton);

    return buttons;
  },

  getTextSelectionCopyButton() {
    const { state } = this;
    if (!state) {
      return null;
    }

    return {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" /></svg>',
      onClick: () => {
        $a.l($a.e.DOC_COPY);
        state.webViewer.UI.iframeWindow.dispatchEvent(new Event('copy'));
      },
      title: $t('Common.copy'),
      dataElement: 'copyButton',
    };
  },

  getCopilotExplainButton() {
    const { state } = this;
    if (!state) {
      return null;
    }

    return {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#2CABAE" d="M19,1L17.74,3.75L15,5L17.74,6.26L19,9L20.25,6.26L23,5L20.25,3.75M9,4L6.5,9.5L1,12L6.5,14.5L9,20L11.5,14.5L17,12L11.5,9.5M19,15L17.74,17.74L15,19L17.74,20.25L19,23L20.25,20.25L23,19L20.25,17.74" /></svg>',
      onClick: async () => {
        const text = state.webViewer.Core.documentViewer.getSelectedText();
        if (!text) {
          return;
        }

        const context = await this.getTextAround(state.docViewer.getCurrentPage(), text);
        docPilotService.explain(text, context);
        detailViewService.setCopilotScope('DOCUMENT');
        detailViewService.openPanel('Copilot');
      },
      title: $t('CaseDetail.Document.letCopilotExplainDocument'),
      dataElement: 'copilotExplainSelectButton',
    };
  },

  getCopyTextSelectionUrlButton() {
    const { state } = this;
    if (!state) {
      return null;
    }

    return {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <path fill="currentColor" d="M10.59 13.41c.41.39.41 1.03 0 1.42c-.39.39-1.03.39-1.42 0a5.003 5.003 0 0 1 0-7.07l3.54-3.54a5.003 5.003 0 0 1 7.07 0a5.003 5.003 0 0 1 0 7.07l-1.49 1.49c.01-.82-.12-1.64-.4-2.42l.47-.48a2.98 2.98 0 0 0 0-4.24a2.98 2.98 0 0 0-4.24 0l-3.53 3.53a2.98 2.98 0 0 0 0 4.24m2.82-4.24c.39-.39 1.03-.39 1.42 0a5.003 5.003 0 0 1 0 7.07l-3.54 3.54a5.003 5.003 0 0 1-7.07 0a5.003 5.003 0 0 1 0-7.07l1.49-1.49c-.01.82.12 1.64.4 2.43l-.47.47a2.98 2.98 0 0 0 0 4.24a2.98 2.98 0 0 0 4.24 0l3.53-3.53a2.98 2.98 0 0 0 0-4.24a.973.973 0 0 1 0-1.42"/></svg>',
      onClick: async () => {
        $a.l($a.e.DOC_COPYLINK);
        state.webViewer.UI.iframeWindow.dispatchEvent(new Event('urlCopy'));
      },
      title: $t('CaseDetail.Document.copySelectionUrl'),
      dataElement: 'copySelectionUrlButton',
    };
  },

  showSplitButton() {
    if (!this.state) {
      return;
    }
    this.state.webViewer.UI.enableElements(['splitButton']);
    this.state.showSplitBtn = true;
  },

  hideSplitButton() {
    if (!this.state) {
      return;
    }
    this.state.webViewer.UI.disableElements(['splitButton']);
    this.state.showSplitBtn = false;
  },

  clearSelection() {
    this.state?.docViewer.clearSelection();
  },

  setPrimaryColor(color: string) {
    const { state } = this;
    if (!state) {
      return;
    }

    const { style } = state.webViewer.UI.iframeWindow.document.documentElement;
    style.setProperty('--primary-color', color);
    style.setProperty('--focus-border', color);
  },

  async getTextAround(page: number, text: string) {
    const { state } = this;
    if (!state) {
      return '';
    }

    const doc = state.docViewer.getDocument();
    const pageText = await doc.loadPageText(page);
    const index = pageText.indexOf(text);
    if (index === -1) {
      return '';
    }

    const start = Math.max(0, index - 150);
    const end = Math.min(pageText.length, index + 150);
    return pageText.substring(start, end);
  },
};

export { pdftronUiHelper };
