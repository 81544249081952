import axios from 'axios';
import dayjs from 'dayjs';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import documentFilterService from '@/case-detail/subviews/documents-list/services/filter/document.filter.service';
import labelService from '@/case-detail/subviews/labels/services/label.service';
import { getMainLabel } from '@/case-detail/subviews/labels/services/labels.utils';
import $a from '@/common/services/analytics/analytics';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import { formatToLocale } from '@/common/services/date.utils';
import fileService from '@/common/services/file.service';

export const EntryType = {
  DOCUMENT: 'DOCUMENT',
  HEADING: 'HEADING',
};

export const ExportView = {
  ExportList: 1,
  DocumentSelection: 2,
  Configuration: 3,
  Share: 4,
};

export const SortingModes = {
  MANUAL: {
    key: 'MANUAL',
    titleKey: 'CaseDetail.Export.SortModes.MANUAL',
    icon: 'mdi-sort',
  },
  ISSUE_DATE_ASC: {
    key: 'ISSUE_DATE_ASC',
    titleKey: 'CaseDetail.Export.SortModes.ISSUE_DATE_ASC',
    icon: 'mdi-sort-calendar-ascending',
  },
  ISSUE_DATE_DESC: {
    key: 'ISSUE_DATE_DESC',
    titleKey: 'CaseDetail.Export.SortModes.ISSUE_DATE_DESC',
    icon: 'mdi-sort-calendar-descending',
  },
  RECEIPT_DATE_ASC: {
    key: 'RECEIPT_DATE_ASC',
    titleKey: 'CaseDetail.Export.SortModes.RECEIPT_DATE_ASC',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" fill="none"><path fill="currentColor" d="M16 7h3v14h2V7h3l-4-4-4 4ZM2.4 9.463l5.6 4.39 5.6-4.39L8 5.073l-5.6 4.39Zm12.6 0v8.78c0 .467-.148.913-.41 1.243-.263.329-.619.514-.99.514H2.4c-.371 0-.727-.185-.99-.514A2.005 2.005 0 0 1 1 18.244V9.463c0-.641.273-1.194.679-1.502L8 3l6.321 4.961c.406.308.679.86.679 1.502Z"/></svg>',
  },
  RECEIPT_DATE_DESC: {
    key: 'RECEIPT_DATE_DESC',
    titleKey: 'CaseDetail.Export.SortModes.RECEIPT_DATE_DESC',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" fill="none"><path fill="currentColor" d="M16 17h3V3h2v14h3l-4 4-4-4ZM2.4 9.463l5.6 4.39 5.6-4.39L8 5.073l-5.6 4.39Zm12.6 0v8.78c0 .467-.148.913-.41 1.243-.263.329-.619.514-.99.514H2.4c-.371 0-.727-.185-.99-.514A2.005 2.005 0 0 1 1 18.244V9.463c0-.641.273-1.194.679-1.502L8 3l6.321 4.961c.406.308.679.86.679 1.502Z"/></svg>',
  },
  DOCTYPE_ASC: {
    key: 'DOCTYPE_ASC',
    titleKey: 'CaseDetail.Export.SortModes.DOCTYPE_ASC',
    icon: 'mdi-sort-bool-ascending',
  },
  DOCTYPE_DESC: {
    key: 'DOCTYPE_DESC',
    titleKey: 'CaseDetail.Export.SortModes.DOCTYPE_DESC',
    icon: 'mdi-sort-bool-descending',
  },
};

export const TocTitles = () => [
  $t('CaseDetail.Export.TocTitles.index'),
  $t('CaseDetail.Export.TocTitles.edition'),
  $t('CaseDetail.Export.TocTitles.toc'),
  $t('CaseDetail.Export.TocTitles.appendices'),
];

class ExportService {
  constructor() {
    this.state = reactive({
      view: ExportView.ExportList,
      error: null,
      isLoading: false,
      isFirstPreview: true,
      allowSave: false,
      showDropOverlay: false,

      // docs
      documentExportsList: null,
      recipientMap: null,
      selectedDocumentExport: null,

      // filter
      labelFilterOptions: [],
      labelFilter: null,
    });
    this.documentExportsMap = null;
    this.currentCase = null;
  }

  async init(curCase) {
    this.currentCase = curCase;
    await this.fetchAll();
  }

  /** GETTERS / SETTERS */

  isLoading() {
    return this.state.isLoading;
  }

  setLoading(isLoading) {
    this.state.isLoading = isLoading;
  }

  getCurrentlySelectedDocumentIds() {
    const entries = this.state.selectedDocumentExport?.documentEntries ?? [];
    return entries.filter((e) => e.type === EntryType.DOCUMENT).map((e) => e.documentId);
  }

  getPreviouslyExportedDocumentIds() {
    return this.state.selectedDocumentExport?.prevDocumentIds ?? [];
  }

  /** CRUD */

  async create(recipient) {
    const response = await axios.put(config.API.EXPORTS_ENDPOINT.CREATE.replace('{legalCaseId}', this.currentCase.id), {
      recipient,
    });

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...response.data,
    };
    this.updateLabelFilterOptions();

    this.overrideDefaultConfig();

    this.state.view = ExportView.DocumentSelection;
    $a.l($a.e.EXPORT_ADD);
  }

  async createUpdate(previousDocumentExport) {
    const response = await axios.put(
      config.API.EXPORTS_ENDPOINT.CREATE_UPDATE.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', previousDocumentExport.id),
      {
        recipient: previousDocumentExport.recipient,
      },
    );
    const prevDocIds = previousDocumentExport.documentEntries.filter((e) => e.type === EntryType.DOCUMENT).map((d) => d.documentId);
    prevDocIds.push(...previousDocumentExport.prevDocumentIds);

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      prevDocumentIds: prevDocIds,
      ...response.data,
    };
    this.updateLabelFilterOptions();
    // NOTE: sets filename
    this.overrideDefaultConfig();

    this.state.view = ExportView.DocumentSelection;
    $a.l($a.e.EXPORT_ADD_UPDATE);
  }

  async saveDocuments() {
    this.sort();

    const response = await axios.patch(
      config.API.EXPORTS_ENDPOINT.DOCUMENTS.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', this.state.selectedDocumentExport.id),
      {
        documentEntries: this.state.selectedDocumentExport.documentEntries,
        sorting: this.state.selectedDocumentExport.configuration.sorting.replace('_ASC', '').replace('_DESC', ''),
      },
    );

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...response.data,
    };
    this.updateLabelFilterOptions();
  }

  async saveConfig() {
    const requestData = {
      ...this.state.selectedDocumentExport.configuration,
    };

    let response;
    try {
      response = await axios.patch(
        config.API.EXPORTS_ENDPOINT.CONFIG.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', this.state.selectedDocumentExport.id),
        requestData,
      );
    } catch (e) {
      handleError($t('CaseDetail.Export.exportCannotBeSavedErrorMessage'), e);
    }

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...response.data,
    };
    this.updateLabelFilterOptions();
  }

  async delete(documentExport) {
    await axios.delete(`${config.API.EXPORTS_ENDPOINT.BASE.replace('{legalCaseId}', this.currentCase.id)}/${documentExport.id}`);

    this.state.selectedDocumentExport = null;
    this.state.documentExportsList = this.state.documentExportsList.filter((de) => de.id !== documentExport.id);
    this.updateMaps();

    $a.l($a.e.EXPORT_DELETE);
  }

  async publish() {
    this.state.isLoading = true;

    // verify documents
    const validDocumentEntries = this.state.selectedDocumentExport.documentEntries.filter((entry) => {
      if (entry.type !== EntryType.DOCUMENT) return true;

      const document = documentService.getDocumentsCache().get(entry.documentId);
      return !!document && document.status === 'ACTIVE';
    });

    if (validDocumentEntries.length !== this.state.selectedDocumentExport.documentEntries.length) {
      appService.info($t('CaseDetail.Export.someDocumentsInvalidInfoMessage'));
      this.state.selectedDocumentExport.documentEntries = [...validDocumentEntries];
      await this.saveDocuments();
      this.state.view = ExportView.DocumentSelection;
      return;
    }

    await this.saveConfig();

    let response;
    try {
      response = await axios.post(
        config.API.EXPORTS_ENDPOINT.PUBLISH.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', this.state.selectedDocumentExport.id),
      );
    } catch (e) {
      handleError($t('CaseDetail.Export.exportPublishErrorMessage'), e);
      return;
    }

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...response.data,
    };

    this.state.isLoading = false;
    this.state.view = ExportView.Share;

    $a.l($a.e.EXPORT_PUBLISH);
  }

  async fetchHistory(exportId) {
    return axios
      .get(config.API.EXPORTS_ENDPOINT.HISTORY.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', exportId))
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseDetail.Export.historyLoadErrorMessage'), e);
      });
  }

  async fetchAll() {
    try {
      const documentExportResponse = await axios.get(config.API.EXPORTS_ENDPOINT.BASE.replace('{legalCaseId}', this.currentCase.id));
      this.state.documentExportsList = documentExportResponse.data;
      this.updateMaps();

      for (const documentExport of this.state.documentExportsList) {
        const prevDocumentIds = [];
        let { previousExportId } = documentExport;
        while (previousExportId) {
          const previousDocumentExport = this.documentExportsMap.get(previousExportId);
          const previousDocumentExportDocumentIds = previousDocumentExport.documentEntries
            .filter((e) => e.type === EntryType.DOCUMENT)
            .map((d) => d.documentId);
          prevDocumentIds.unshift(...previousDocumentExportDocumentIds);
          previousExportId = previousDocumentExport.previousExportId;
        }
        documentExport.prevDocumentIds = prevDocumentIds;
      }
    } catch (e) {
      handleError($t('CaseDetail.Export.exportsLoadErrorMessage'), e);
    }
  }

  overrideDefaultConfig() {
    if (!this.state.selectedDocumentExport.configuration.filename) {
      const [filenames] = this.getFilenamesForSelect();
      this.state.selectedDocumentExport.configuration.filename = filenames.value;
    }
    this.saveConfig();
  }

  /** PDF */

  async downloadPdf(documentExport) {
    appService.info($t('Common.File.preparingDownload'));
    const filename = this.parseFilenameTemplate(documentExport.configuration.filename, documentExport);
    fileService.download(documentExport.fileUri, filename);
  }

  /** DOCUMENTS */

  async addDocuments(documentIds) {
    // NOTE(ndv): the idea is to not impact the loading when it's already managed outside this method, e.g. from methods that call this method inside them
    let resetLoading = false;
    if (!this.isLoading()) {
      this.setLoading(true);
      resetLoading = true;
    }

    let added = 0;
    for (const documentId of documentIds) {
      const document = documentService.getDocumentsCache().get(documentId);
      if (
        !this.state.selectedDocumentExport.documentEntries.find((e) => e.documentId === documentId) &&
        !this.state.selectedDocumentExport.prevDocumentIds?.includes(documentId) &&
        document.status === 'ACTIVE'
      ) {
        this.state.selectedDocumentExport.documentEntries.push({
          type: EntryType.DOCUMENT,
          documentId,
          sourceFileId: document.sourceFileId,
          fileReference: document.sourceFileUploadFilename,
        });
        added++;
      }
    }

    if (added > 0) {
      await this.saveDocuments();
    }

    // see above
    if (resetLoading) {
      this.setLoading(false);
    }

    return added;
  }

  async addFilteredDocuments() {
    this.setLoading(true);

    const filteredDocuments = documentService.getFilteredDocuments();
    // get timeline documents, except deleted ones
    const timelineDocumentIds = filteredDocuments.filter((d) => d.status === 'ACTIVE').map((d) => d.id);
    // store current documentIds before change to allow undo action
    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    const added = await this.addDocuments(timelineDocumentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === timelineDocumentIds.length) {
        appService.info($t('CaseDetail.Export.addedAllVisibleDocumentsInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.addedDocumentsWithLimitationsInfoMessage'), undoFn);
      }
    } else if (added === 0 && timelineDocumentIds.length > 0) {
      appService.info($t('CaseDetail.Export.documentsWasNotAddedInfoMessage'));
    } else {
      appService.info($t('CaseDetail.Export.noVisibleDocumentsInfoMessage'));
    }

    this.setLoading(false);

    $a.l($a.e.EXPORT_DOCS_ADD_ALL);
  }

  async addAllDocumentsWithLabel(labelId, isTypeOther) {
    // sanity check
    if (!labelId) return;

    this.setLoading(true);

    const label = labelService.getLabel(labelId);
    const labelSublabels = labelService.getAllSublabels(labelId);

    // compute label ids
    const labelIds = !label || isTypeOther || labelSublabels.length === 0 ? [labelId] : [labelId, ...labelSublabels.map((l) => l.id)];
    // filter
    let filteredDocuments = documentService.getDocuments().filter((d) => d.labels && d.labels.some((id) => labelIds.includes(id)));

    const folderFilter = documentFilterService.currentFilterValue('folder');
    if (folderFilter.length > 0) {
      filteredDocuments = filteredDocuments.filter((d) => folderFilter.includes(d.metadata.FOLDER.value));
    }

    const documentIds = filteredDocuments.map((d) => d.id);
    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    const added = await this.addDocuments(documentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === documentIds.length) {
        appService.info($t('CaseDetail.Export.documentsAddedInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.documentNotYetAvailableAddedInfoMessage'), undoFn);
      }
    } else if (added === 0 && documentIds.length > 0) {
      appService.info($t('CaseDetail.Export.noDcumentsAddedInfoMessage'));
    }

    this.setLoading(false);
  }

  async addAllImportantDocuments() {
    this.setLoading(true);

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    let filteredDocuments = documentService.getDocuments().filter((d) => d.metadata.IMPORTANT.value === 'true');
    const folderFilter = documentFilterService.currentFilterValue('folder');
    if (folderFilter.length > 0) {
      filteredDocuments = filteredDocuments.filter((d) => folderFilter.includes(d.metadata.FOLDER.value));
    }
    const documentIds = filteredDocuments.map((d) => d.id);

    const added = await this.addDocuments(documentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === documentIds.length) {
        appService.info($t('CaseDetail.Export.documentsAddedInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.documentNotYetAvailableAddedInfoMessage'), undoFn);
      }
    } else if (added === 0 && documentIds.length > 0) {
      appService.info($t('CaseDetail.Export.noDcumentsAddedInfoMessage'));
    }

    this.setLoading(false);
  }

  async addHeading(index) {
    const newDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    newDocumentEntries.splice(index, 0, {
      type: EntryType.HEADING,
      text: '',
      documentId: null,
    });

    this.state.selectedDocumentExport.documentEntries = newDocumentEntries;
    await this.saveDocuments();
    $a.l($a.e.EXPORT_DOCS_HEADING);
  }

  async updateDocumentEntry(index, newData) {
    this.state.selectedDocumentExport.documentEntries[index] = {
      ...this.state.selectedDocumentExport.documentEntries[index],
      ...newData,
    };
  }

  async deleteAllVisibleDocuments() {
    this.setLoading(true);

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    this.state.selectedDocumentExport.documentEntries = [...this.state.selectedDocumentExport.documentEntries].filter(
      (entry) => !this.isVisible(entry.documentId),
    );
    await this.saveDocuments();

    appService.info($t('CaseDetail.Export.allVisibleDocumentsRemovedInfoMessage'), async () => {
      this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
      await this.saveDocuments();
    });

    this.setLoading(false);

    $a.l($a.e.EXPORT_DOCS_CLEAR);
  }

  async deleteDocument(documentId) {
    if (!this.state.selectedDocumentExport.documentEntries.find((e) => e.documentId === documentId)) return;

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    this.state.selectedDocumentExport.documentEntries = [...this.state.selectedDocumentExport.documentEntries].filter(
      (e) => e.documentId !== documentId,
    );
    await this.saveDocuments();

    appService.info($t('CaseDetail.Export.documentRemovedInfoMessage'), async () => {
      this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
      await this.saveDocuments();
    });

    $a.l($a.e.EXPORT_DOCS_DELETE);
  }

  async removeDocumentEntry(index) {
    if (index > this.state.selectedDocumentExport.documentEntries.length || index < 0) return;

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    const newDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    newDocumentEntries.splice(index, 1);

    this.state.selectedDocumentExport.documentEntries = newDocumentEntries;
    await this.saveDocuments();

    appService.info($t('CaseDetail.Export.headlineRemovedInfoMesssage'), async () => {
      this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
      await this.saveDocuments();
    });

    $a.l($a.e.EXPORT_DOCS_HEADING);
  }

  refreshList() {
    if (!this.state.selectedDocumentExport) {
      return;
    }
    const oldDocumentLength = this.state.selectedDocumentExport.documentEntries.length;
    this.state.selectedDocumentExport.documentEntries = this.state.selectedDocumentExport?.documentEntries?.filter((e) => {
      if (e.type === EntryType.DOCUMENT) {
        return documentService.getDocumentsCache().has(e.documentId);
      }
      return true;
    });
    if (oldDocumentLength !== this.state.selectedDocumentExport.documentEntries.length) {
      this.saveDocuments();
    }
  }

  isVisible(documentId) {
    if (!this.state.labelFilter) {
      return true;
    }
    const labelId = this.state.labelFilter.id;
    const doc = documentService.getDocumentsCache().get(documentId);
    if (labelId === null && (!doc.labels || doc.labels.length === 0)) {
      return true;
    }
    return doc.labels.some((lid) => lid === labelId);
  }

  hasVisibileDocuments() {
    return this.state.selectedDocumentExport?.documentEntries
      ?.filter((e) => e.type === EntryType.DOCUMENT)
      ?.some((e) => this.isVisible(e.documentId));
  }

  hasHeadings() {
    return this.state.selectedDocumentExport?.documentEntries?.filter((e) => e.type === EntryType.HEADING)?.length > 0;
  }

  async setSorting(sortingModeKey) {
    if (!SortingModes[sortingModeKey]) return;

    this.state.selectedDocumentExport.configuration.sorting = sortingModeKey;

    $a.l($a.e.EXPORT_DOCS_SORT_MODE);
    await this.saveDocuments();
  }

  sort() {
    const { sorting } = this.state.selectedDocumentExport.configuration;

    if (sorting === SortingModes.MANUAL.key) return;

    // store each non-DOCUMENT entry and its index (will be readded later)
    const m = new Map();
    for (let i = 0; i < this.state.selectedDocumentExport.documentEntries.length; i++) {
      const entry = this.state.selectedDocumentExport.documentEntries[i];
      if (entry.type === EntryType.DOCUMENT) continue;

      m.set(i, entry);
    }

    // copy list of document entries of type DOCUMENT (we only sort those)
    const documentEntriesToSort = [...this.state.selectedDocumentExport.documentEntries.filter((e) => e.type === EntryType.DOCUMENT)];

    // sort them based on sorting
    if (sorting.startsWith('ISSUE_DATE') || sorting.startsWith('RECEIPT_DATE')) {
      this.sortByDate(documentEntriesToSort, sorting);
    } else if (sorting.startsWith('DOCTYPE')) {
      this.sortByType(documentEntriesToSort, sorting);
    }

    // insert non-DOCUMENT entries
    for (const [index, entry] of m.entries()) {
      documentEntriesToSort.splice(index, 0, entry);
    }

    this.state.selectedDocumentExport.documentEntries = documentEntriesToSort;
  }

  sortByDate(list, sorting) {
    list.sort((a, b) => {
      let aDocument = documentService.getDocumentsCache().get(a.documentId);
      let bDocument = documentService.getDocumentsCache().get(b.documentId);
      let key;
      if (sorting.endsWith('_DESC')) {
        [aDocument, bDocument] = [bDocument, aDocument];
        key = sorting.replace('_DESC', '');
      } else {
        key = sorting.replace('_ASC', '');
      }
      return aDocument.metadata[key].value.localeCompare(bDocument.metadata[key].value);
    });
  }

  sortByType(list, sorting) {
    list.sort((a, b) => {
      let aDocument = documentService.getDocumentsCache().get(a.documentId);
      let bDocument = documentService.getDocumentsCache().get(b.documentId);
      const aLabel = getMainLabel(aDocument.labels, labelService.getLabels());
      const bLabel = getMainLabel(bDocument.labels, labelService.getLabels());

      if (aLabel === bLabel) {
        if (sorting.endsWith('_DESC')) {
          [aDocument, bDocument] = [bDocument, aDocument];
        }
        return aDocument.metadata.ISSUE_DATE.value.localeCompare(bDocument.metadata.ISSUE_DATE.value);
      }

      // sort by label
      if (!aLabel && bLabel) return -1;
      if (aLabel && !bLabel) return 1;

      let aSorting = aLabel.sorting;
      let bSorting = bLabel.sorting;

      // NOTE(mba): the main label, e.g. "Medizinisch allgemein", should always be after the sub labels
      if (aSorting && !aSorting.includes('.')) {
        aSorting += '.99';
      }
      if (bSorting && !bSorting.includes('.')) {
        bSorting += '.99';
      }

      if (aSorting && bSorting) return aSorting.localeCompare(bSorting);
      if (aSorting && !bSorting) return -1;
      if (!aSorting && bSorting) return 1;
      return aLabel.title.localeCompare(bLabel.title);
    });
  }

  changeLabelFilter(value) {
    this.state.labelFilter = value;
    if (value) {
      documentFilterService.setFilter('labels', value.id ? [value.id] : []);
    }
    $a.l($a.e.EXPORT_DOCS_FILTER);
  }

  updateLabelFilterOptions() {
    const labelMap = new Map();
    let otherCount = 0;

    for (const entry of this.state.selectedDocumentExport.documentEntries) {
      if (entry.type !== EntryType.DOCUMENT) continue;

      const doc = documentService.getDocumentsCache().get(entry.documentId);

      if (!doc.labels || doc.labels?.length === 0) {
        otherCount++;
      }
      for (const label of doc.labels) {
        if (labelMap.has(label)) {
          labelMap.set(label, labelMap.get(label) + 1);
        } else {
          labelMap.set(label, 1);
        }
      }
    }

    const options = [];
    for (const [labelId, count] of labelMap.entries()) {
      const label = labelService.getLabel(labelId);
      const text = `${label.title} (${count})`;
      options.push({
        text,
        id: labelId,
        labelText: label.title,
        labelColor: label.color,
        labelIcon: label.icon,
      });
    }

    options.sort((a, b) => a.labelText.toLowerCase().localeCompare(b.labelText.toLowerCase()));

    if (otherCount > 0) {
      options.push({
        text: $t('CaseDetail.Export.otherCount', [otherCount]),
        id: null,
        labelText: $t('CaseDetail.Export.others'),
        labelColor: 'primary',
        labelIcon: 'mdi-label-off',
      });
    }
    this.state.labelFilterOptions = options;
  }

  selectDocument(documentId) {
    this.state.selectedDocumentId = documentId;
    broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', { docId: documentId, page: 0, scroll: false, forceShow: false, iterate: false });
  }

  async selectDocumentAtIndex(index) {
    const entry = this.state.selectedDocumentExport.documentEntries[index];
    if (!entry) return;
    if (entry.type === EntryType.DOCUMENT) {
      await this.selectDocument(entry.documentId);
    }
  }

  updateMaps() {
    this.state.documentExportsList.sort((a, b) => dayjs(b.created) - dayjs(a.created));

    this.state.recipientMap = new Map();
    this.documentExportsMap = new Map();

    for (const documentExport of this.state.documentExportsList) {
      this.documentExportsMap.set(documentExport.id, documentExport);

      const des = this.state.recipientMap.get(documentExport.recipient);
      if (des) {
        des.push(documentExport);
      } else {
        this.state.recipientMap.set(documentExport.recipient, [documentExport]);
      }
    }
  }

  /* Config Panel */
  getFilenamesForSelect() {
    const filenames = [];
    const templates = this.state.selectedDocumentExport.configuration.filenameTemplates.split('\n');

    for (const template of templates) {
      const parsed = this.parseFilenameTemplate(template, this.state.selectedDocumentExport);
      if (parsed.trim() !== '') {
        filenames.push({ value: template, text: parsed });
      }
    }
    return filenames;
  }

  parseFilenameTemplate(template, documentExport) {
    const actsStart = documentExport?.previousExportId ? documentExport.prevDocumentIds.length + 1 : 1;
    const actsEnd = documentExport?.previousExportId
      ? documentExport.prevDocumentIds.length + documentExport.documentEntries.length
      : documentExport.documentEntries.length;
    const patterns = {
      '{actsRange}': `${actsStart}-${actsEnd}`,
      '{initials}': `${this.currentCase.displayLabel
        .split(' ')
        .map((s) => s.charAt(0))
        .join('')}`,
      '{date}': dayjs().format('YYYY-MM-DD'),
      '{reference}': this.currentCase.reference,
    };

    for (const [search, replace] of Object.entries(patterns)) {
      template = template.replace(search, replace);
    }
    return template;
  }

  getConfigurationTitleSuffixes() {
    return {
      title: `${this.currentCase.displayLabel}`,
      subtitle: `${formatToLocale(dayjs())}`,
    };
  }

  /* Navigation */
  async back() {
    await this.fetchAll();
    this.state.view = ExportView.ExportList;
    this.state.isLoading = false;
    this.state.isFirstPreview = true;
    this.state.allowSave = false;
    this.state.labelFilter = null;
    this.state.labelFilterOptions = [];
    $a.l($a.e.EXPORT_BACK);
  }

  editDraft(documentExport) {
    this.state.selectedDocumentExport = documentExport;
    this.overrideDefaultConfig();
    this.refreshList();
    this.updateLabelFilterOptions();
    this.state.view = ExportView.DocumentSelection;
    $a.l($a.e.EXPORT_EDIT);
  }

  dragStart() {
    if (this.state.view === ExportView.DocumentSelection) {
      this.state.showDropOverlay = true;
    }
  }

  dragEnd() {
    this.state.showDropOverlay = false;
  }

  destroy() {
    this.state.documentExportsList = null;
    this.state.recipientMap = null;
    this.documentExportsMap = null;
    this.currentCase = null;

    // view
    this.state.isLoading = false;
    this.state.isFirstPreview = true;
    this.state.error = null;
    this.state.view = ExportView.ExportList;

    this.state.selectedDocumentExport = null;

    // filter
    this.state.labelFilter = null;
    this.state.labelFilterOptions = [];
  }
}

export default new ExportService();
export const ExportServiceClass = ExportService;
