<template>
  <v-container class="fill-height" fluid>
    <v-container class="wrapper">
      <v-card flat class="outlined" data-testid="error">
        <div class="pt-6 px-8">
          <v-icon size="x-large" color="primary">
            {{ errorData.icon }}
          </v-icon>
          <h2 class="mt-6">
            {{ errorData.title }}
          </h2>
          <div class="text-body-1 py-6">
            {{ errorData.body }}
          </div>
          <div class="px-4 pb-4">
            <v-btn
              v-if="errorType === 'ACCESS_EXPIRED' || errorType === 'ACCESS_REVOKED'"
              class="ma-2"
              color="primary"
              variant="outlined"
              @click="$router.push({ name: 'requests' })"
            >
              {{ $t('App.Error.goToTicketOverview') }}
            </v-btn>

            <!-- Intercom: do not remove id attribute -->
            <v-btn v-if="errorType !== 'FATAL' && errorType !== 'MAINTENANCE_LOCK'" class="intercom-trigger ma-2" color="primary" variant="outlined">
              {{ $t('Common.help') }}
            </v-btn>

            <v-btn
              v-if="errorType !== 'ACCESS_EXPIRED' && errorType !== 'ACCESS_REVOKED'"
              class="ma-2"
              color="primary"
              variant="outlined"
              @click="reload()"
            >
              {{ $t('App.Error.reloadApp') }}
            </v-btn>

            <v-btn
              v-if="errorType === 'ACCESS_DENIED' || errorType === 'MAINTENANCE_LOCK'"
              class="ml-2"
              color="primary"
              variant="flat"
              @click="logout()"
            >
              {{ $t('App.Error.relogin') }}
            </v-btn>
          </div>
        </div>
        <v-divider />
        <p class="my-3 text-body-2">
          {{ $t('App.Error.weAreHereIfProblemPersists') }}
          <br />
          <i18n-t keypath="App.Error.contactUsVia" scope="global">
            <a class="intercom-trigger">Chat</a>
            <a href="mailto:support@legal-i.ch">support@legal-i.ch</a>
          </i18n-t>
        </p>
      </v-card>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { authService } from '@/common/services/auth/auth.service';
import entityService, { AppErrorKey } from '@/common/services/entity.service';

export default defineComponent({
  props: {
    errorType: {
      type: String as PropType<AppErrorKey>,
      required: true,
    },
  },

  data() {
    return {
      errorData: entityService.APP_ERRORS[this.errorType],
    };
  },

  methods: {
    reload() {
      window.location.reload();
    },
    async logout() {
      await authService.logout();
      location.reload();
    },
  },
});
</script>

<style scoped>
.wrapper {
  width: 650px;
  word-break: break-word;
  text-align: center;
}
</style>
