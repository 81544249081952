<template>
  <v-dialog v-model="dialog" max-width="940px">
    <v-card :data-testid="editingMode ? 'modal_editCase' : 'modal_addCase'">
      <v-card-item class="pl-5 py-2 pr-2">
        <v-card-title class="d-flex align-center">
          <span v-if="editingMode">{{ $t('CaseList.AddEditCase.editCaseData') }}</span>
          <span v-else>{{ $t('Common.createNewCase') }}</span>
          <v-spacer />
          <v-btn v-blur icon="mdi-close" data-testid="btn-close" density="comfortable" variant="text" @click="clearAndClose" />
        </v-card-title>
      </v-card-item>
      <v-divider />
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <div class="d-flex">
            <v-text-field
              ref="firstName"
              v-model="caseData.pii.PII_FIRSTNAME"
              :error-messages="firstnameErrors"
              class="pa-2"
              :label="$t('CaseList.AddEditCase.firstName')"
              maxlength="255"
              autofocus
              data-testid="input_firstName"
              @blur="checkOtherCases"
            />
            <v-text-field
              ref="lastName"
              v-model="caseData.pii.PII_LASTNAME"
              :error-messages="lastnameErrors"
              class="pa-2"
              :label="$t('CaseList.AddEditCase.lastName')"
              maxlength="255"
              data-testid="input_lastName"
              @blur="checkOtherCases"
            />
            <v-text-field
              ref="company"
              v-model="caseData.pii.PII_COMPANY"
              :error-messages="companyErrors"
              class="pa-2"
              :label="$t('Common.customerCompany')"
              maxlength="255"
              data-testid="input_company"
              @blur="checkOtherCases"
            />
          </div>
          <div v-if="!editingMode && existingCase" class="d-flex px-2">
            <v-alert class="flex-grow-1" color="primary" density="compact" variant="outlined">
              <div class="d-flex justify-space-between align-center">
                <span class="flex-grow-1">
                  {{ $t('CaseList.AddEditCase.similarCaseExists') }}
                </span>
                <div class="flex-grow-1">
                  <div class="d-flex justify-end align-center data-hj-suppress">
                    <v-btn
                      color="primary"
                      variant="flat"
                      @click="
                        $emit('switch-case', existingCase);
                        clearAndClose();
                      "
                    >
                      {{ $t('CaseList.AddEditCase.addFileTo', [existingCase.displayLabel]) }}
                    </v-btn>
                    <v-btn v-blur class="ml-2" color="primary" density="comfortable" icon="mdi-close" variant="text" @click="existingCase = null" />
                  </div>
                </div>
              </div>
            </v-alert>
          </div>
          <div class="mt-4 pa-2">
            {{ $t('CaseList.AddEditCase.internalInformation') }}
          </div>
          <div class="d-flex">
            <div style="width: 309px">
              <v-autocomplete
                v-model="caseData.owner"
                class="pa-2 item"
                maxlength="255"
                :items="users"
                :rules="rules"
                auto-select-first
                item-title="name"
                item-value="user_id"
                :label="$t('CaseList.AddEditCase.responsiblePerson')"
                data-testid="input_owner"
              />
            </div>
            <v-text-field
              v-if="$hasFeature('ENABLE_MANUAL_CASE_MANAGEMENT')"
              v-model="caseData.reference"
              class="pa-2"
              :hint="$t('CaseList.AddEditCase.internalReferenceOfCase')"
              :label="$t('CaseList.AddEditCase.caseReference')"
              maxlength="255"
              data-testid="input_reference"
            />
            <v-autocomplete
              v-if="$hasFeature('ENABLE_MANUAL_CASE_MANAGEMENT') && assignableAccessGroups.length > 0"
              v-model="caseData.accessGroup"
              class="pa-2"
              maxlength="255"
              :items="assignableAccessGroups"
              :label="$t('CaseList.AddEditCase.authorizationGroup')"
              data-testid="input_authGroup"
            />
          </div>
        </v-form>
        <div v-if="!editingMode" class="d-flex flex-wrap">
          <span class="mt-2 pa-2">
            {{ $t('CaseList.AddEditCase.files') }}
          </span>
          <FileUpload :new-source-file="newSourceFile" :show-add-button="false" />
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end">
        <template v-if="editingMode">
          <v-btn color="primary" variant="text" data-testid="btn_cancel" @click="clearAndClose">
            {{ $t('Common.cancel') }}
          </v-btn>
          <v-btn color="primary" variant="flat" data-testid="btn_saveUpdated" @click="saveUpdated">
            {{ $t('Common.save') }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn color="primary" variant="text" data-testid="btn_cancel" @click="clearAndClose">
            {{ $t('Common.cancel') }}
          </v-btn>
          <v-btn
            :disabled="newSourceFile.originalFileUris.length === 0 || creating"
            color="primary"
            variant="flat"
            data-testid="btn_saveNew"
            :loading="creating"
            @click="saveNew"
          >
            {{ $t('Common.createCase') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import leven from 'leven';
import { defineComponent, PropType } from 'vue';
import { VForm } from 'vuetify/lib/components/index.mjs';

import appService from '@/app/services/app.service';
import detailViewService, { CurrentLegalCase } from '@/case-detail/services/detail.view.service';
import FileUpload from '@/case-list/components/FileUpload.vue';
import { type LegalCase, legalCaseService } from '@/case-list/services/legalcase.service';
import { User } from '@/common/generated-types/openapi';
import { authService } from '@/common/services/auth/auth.service';
import { OTHER_FOLDER_KEY } from '@/common/services/folder.utils';
import userService from '@/common/services/users/user.service';
import { API } from '@/common/types/api.types';

interface LocalCreateUpdateRequest extends API.LegalCase.CreateUpdateRequest {
  pii: {
    [API.LegalCase.PII_KEY.PII_FIRSTNAME]: string;
    [API.LegalCase.PII_KEY.PII_LASTNAME]: string;
    [API.LegalCase.PII_KEY.PII_COMPANY]: string;
  };
}

export default defineComponent({
  components: { FileUpload },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    editingMode: {
      type: Boolean,
      required: true,
    },
    inputCase: {
      type: Object as PropType<LegalCase | CurrentLegalCase | null>,
      default: null,
    },
  },

  emits: ['close', 'switch-case'],

  data() {
    return {
      caseData: {
        pii: {
          PII_FIRSTNAME: '',
          PII_LASTNAME: '',
          PII_COMPANY: '',
        },
        reference: '',
        owner: '',
        accessGroup: '',
      } as LocalCreateUpdateRequest,
      creating: false,
      newSourceFile: {
        originalFileUris: [],
        uploadFilenames: [],
        folder: OTHER_FOLDER_KEY,
        settings: {},
      },
      valid: false,
      existingCase: null as null | LegalCase,
      // validation
      rules: [(v: string) => !!v || this.$t('CaseList.AddEditCase.pleaseFillThisField')],
      firstnameErrors: [] as string[],
      lastnameErrors: [] as string[],
      companyErrors: [] as string[],
    };
  },

  computed: {
    cases() {
      return legalCaseService.state.cases;
    },
    assignableAccessGroups() {
      return userService.getUser(authService.state.userId)?.accessGroups ?? [];
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    users() {
      return userService.getUsers() as User[];
    },
  },

  watch: {
    inputCase: {
      handler() {
        if (this.editingMode && this.inputCase) {
          this.caseData = {
            pii: {
              PII_FIRSTNAME: this.inputCase.pii.PII_FIRSTNAME.value,
              PII_LASTNAME: this.inputCase.pii.PII_LASTNAME.value,
              PII_COMPANY: this.inputCase.pii.PII_COMPANY.value,
            },
            reference: this.inputCase.reference,
            owner: this.inputCase.owner,
            accessGroup: this.inputCase.accessGroup ?? '',
            labeled: this.inputCase.labeled,
          };
        }
      },
      immediate: true,
    },
  },

  methods: {
    async validate() {
      const form = this.$refs.form as VForm | undefined;
      await form?.validate();
      if (!form?.isValid) {
        return false;
      }

      const firstname = this.caseData.pii!.PII_FIRSTNAME.trim();
      const lastname = this.caseData.pii!.PII_LASTNAME.trim();
      const company = this.caseData.pii!.PII_COMPANY.trim();

      // check if at least the full name of the person or the name of company is given, if both are given its also ok.
      const fullname = firstname && lastname;
      if (fullname || company) {
        return true;
      }

      const errorMessage = this.$t('CaseList.AddEditCase.enterNameOfPerson');
      if (!firstname) {
        this.firstnameErrors.push(errorMessage);
      }
      if (!lastname) {
        this.lastnameErrors.push(errorMessage);
      }
      if (!company) {
        this.companyErrors.push(errorMessage);
      }
      return false;
    },
    clearAndClose() {
      if (this.$refs.form) {
        (this.$refs.form as VForm).resetValidation();
      }

      this.caseData = {
        pii: {
          PII_FIRSTNAME: '',
          PII_LASTNAME: '',
          PII_COMPANY: '',
        },
        reference: '',
        owner: '',
        accessGroup: '',
        labeled: [],
      };
      this.newSourceFile = {
        originalFileUris: [],
        uploadFilenames: [],
        folder: OTHER_FOLDER_KEY,
        settings: {},
      };
      this.dialog = false;
      this.existingCase = null;

      this.firstnameErrors = [];
      this.lastnameErrors = [];
      this.companyErrors = [];
    },
    checkOtherCases() {
      if (this.editingMode) return;
      if (this.caseData.pii!.PII_LASTNAME && this.caseData.pii!.PII_LASTNAME) {
        for (const ca of this.cases) {
          if (
            this.isSimilar(
              this.caseData.pii!.PII_LASTNAME.toLowerCase(),
              ca.pii?.PII_LASTNAME.value.toLowerCase(),
              this.caseData.pii!.PII_FIRSTNAME.toLowerCase(),
              ca.pii?.PII_FIRSTNAME.value.toLowerCase(),
            )
          ) {
            this.existingCase = ca;
            break;
          }
        }
      }
      if (this.caseData.pii!.PII_COMPANY) {
        for (const ca of this.cases) {
          if (this.adaptiveIsSimilar(this.caseData.pii!.PII_COMPANY.toLowerCase(), ca.pii?.PII_COMPANY.value.toLowerCase())) {
            this.existingCase = ca;
            break;
          }
        }
      }
    },
    isSimilar(thisLastname: string, otherLastname: string, thisFirstname: string, otherFirstname: string) {
      return (
        (this.adaptiveIsSimilar(thisLastname, otherLastname) && this.adaptiveIsSimilar(thisFirstname, otherFirstname)) ||
        // or fields mixed up
        (this.adaptiveIsSimilar(thisLastname, otherFirstname) && this.adaptiveIsSimilar(thisFirstname, otherLastname))
      );
    },
    adaptiveIsSimilar(a: string, b: string) {
      const inputLength = Math.floor((a.length + b.length) / 2);
      // <3: must match
      if (inputLength < 3) {
        return a === b;
      }
      // >5: 3 allowed
      let allowedDist = 3;
      // <6:  1 allowed
      if (inputLength < 5) {
        allowedDist = 2;
      }
      return leven(a, b) < allowedDist;
    },
    async saveNew() {
      if (!(await this.validate())) return;
      this.creating = true;

      // persist changes
      const addCaseOperation = await legalCaseService.addCase(this.caseData, this.newSourceFile);
      this.creating = false;

      if (addCaseOperation) {
        let infoMessage = this.$t('CaseList.AddEditCase.caseCreatedConfirmation');
        if (this.$hasFeature('ENABLE_NOTIFICATION_LEGALCASE_READY')) {
          infoMessage += ` ${this.$t('CaseList.AddEditCase.caseCreatedNotificationConfirmation')}`;
        }
        appService.info(infoMessage);
        this.clearAndClose();
      }
    },
    async saveUpdated() {
      if (!(await this.validate())) return;

      // persist changes
      if (detailViewService.getCurrentLegalCase()) {
        // detail view
        detailViewService.updateCurrentLegalCase({ ...this.caseData });
      } else {
        // case list
        legalCaseService.editCase(this.inputCase!.id, { ...this.caseData });
      }
      this.clearAndClose();
    },
  },
});
</script>

<style scoped>
/* eslint-disable-next-line vue-scoped-css/no-unused-selector */
.dropzone {
  background-color: rgb(var(--v-theme-surfaceVariant));
  border: 1px solid rgb(var(--v-theme-primary));
}
</style>
