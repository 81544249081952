<template>
  <v-app>
    <AppBar />
    <v-alert
      v-if="authService.hasFeature('ENABLE_MAINTENANCE_LOCK')"
      class="mt-10 pa-8"
      title="Workspace locked with Maintenance Lock"
      text="Only legal-i team members can access this workspace."
      type="error"
    ></v-alert>

    <v-main class="main">
      <!-- page content -->
      <AppError v-if="appService.state.appError" :error-type="appService.state.appError" />
      <router-view v-else />

      <!-- snackbars -->
      <v-snackbar v-model="appService.state.errorSnackbarTrigger" :timeout="30000" style="z-index: 1002" color="error">
        <span class="text-subtitle-1">{{ appService.state.errorSnackbarMessage }}</span>
        <template #actions>
          <v-btn class="on-error" icon="mdi-close" size="small" @click="appService.state.errorSnackbarTrigger = false" />
        </template>
      </v-snackbar>
      <v-snackbar v-model="appService.state.snackbarTrigger" :timeout="appService.state.snackbarTimeout" color="info">
        <span class="text-subtitle-1">{{ appService.state.snackbarMessage }}</span>
        <template #actions>
          <v-btn v-if="appService.state.snackbarUndo" class="text-subtitle-2" color="primary-lighten-3" variant="text" @click="appService.undo()">
            {{ $t('Common.undo') }}
          </v-btn>
          <v-btn color="outline" icon="mdi-close" size="small" @click="appService.close()" />
        </template>
      </v-snackbar>
    </v-main>
    <v-snackbar v-model="appService.state.outdated" location="bottom" timeout="-1" color="background">
      <p class="text-center">
        <v-avatar size="32">
          <v-icon>mdi-update</v-icon>
        </v-avatar>
      </p>
      <p class="text-center mx-auto" :style="{ maxWidth: '20rem' }">
        {{ $t('App.newVersionAvailable') }}
        {{ $t('App.pleaseUpdateVersion') }}
      </p>
      <div class="d-flex justify-center">
        <v-btn class="ma-2 mx-auto" color="accent" variant="flat" size="small" @click="loadNewVersion()">
          {{ $t('Common.update') }}
        </v-btn>
      </div>
    </v-snackbar>
    <ConfirmationDialog />
  </v-app>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import AppBar from '@/app/app-bar/AppBar.vue';
import ConfirmationDialog from '@/app/components/ConfirmationDialog.vue';
import AppError from '@/app/components/errors/AppError.vue';
import { config } from '@/app/config';
import { i18nService } from '@/app/i18n/i18n.service';
import { Locale } from '@/app/i18n/locale';
import notificationService from '@/app/notification/services/notification.service';
import appService from '@/app/services/app.service';
import { websocketService } from '@/app/services/websocket.service';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import detachedWindowService from '@/common/services/detached-window.service';
import { folderService } from '@/common/services/folder.service';
import logger from '@/common/services/logging';
import pollingService from '@/common/services/polling.service';
import userService from '@/common/services/users/user.service';

export default {
  components: {
    ConfirmationDialog,
    AppBar,
    AppError,
  },
  data() {
    return {
      appService,
      authService,
    };
  },
  computed: {
    userIsLoggedIn() {
      return authService.state.isLoggedIn || false;
    },
  },
  watch: {
    $route(to) {
      // localization for public pages like sharing where we don't have preferred user language from BE
      if (to.meta?.isPublic && !i18nService.state.initialized) {
        const locale = Locale.fromBrowser();
        i18nService.setLocale(locale, this.$vuetify);
      }
    },
    userIsLoggedIn(to) {
      if (to) {
        // app loaded and user logged in
        websocketService.connect(); // Wait for WebSocket connection
        const userLocale = Locale.fromString(authService.state.data.userDetails.locale);
        i18nService.setLocale(userLocale, this.$vuetify);
        userService.init();
        folderService.init();
        pollingService.add('notification', () => notificationService.loadNotifications(), config.NOTIFICATION.POLLING_RATE);
      }
    },
  },
  async created() {
    appService.setVuetifyInstance(this.$vuetify);
    appService.setContextId(uuidv4());
    if (config.VERSION !== 'Development Build') {
      pollingService.add('app-version', () => this.versionCheck(), 30010);
    }
  },
  mounted() {
    window.addEventListener('beforeunload', () => {
      if (window.name.includes('notebookWindow') || window.name.includes('collaborationTicketWindow') || window.name.includes('copilot')) {
        // don't close all detached windows if detached window is closed
        return;
      }
      broadcastEventBus.emit('MAIN_WINDOW_CLOSED_EVENT', {});
      detachedWindowService.closeAllDetachedWindows();
      this.$a.l(this.$a.e.APP_EXIT);
    });
  },
  unmounted() {
    pollingService.destroy();
    detachedWindowService.destroy();
  },

  methods: {
    async versionCheck() {
      const apiResponse = await axios.get(config.API.VERSION);
      const apiVersion = apiResponse.data.shortCommit;
      const s3AppResponse = await fetch(config.APP_VERSION_URL);
      let s3AppVersion = await s3AppResponse.text();
      s3AppVersion = s3AppVersion.trim().substring(0, 7);
      const runningAppVersion = config.VERSION.substring(0, 7);

      if (runningAppVersion === s3AppVersion && runningAppVersion === apiVersion && s3AppVersion === apiVersion) {
        // all in sync, return
        return;
      }

      logger.info('Version Check:');
      logger.info(`API: ${apiVersion}`);
      logger.info(`S3 App: ${s3AppVersion}`);
      logger.info(`Running App: ${runningAppVersion}`);
      if (s3AppVersion !== apiVersion) {
        logger.info('Deployment in progress, update not ready yet');
        return;
      }
      appService.outdated();
    },
    loadNewVersion() {
      document.location.reload();
    },
  },
};
</script>

<!-- eslint-disable vue-scoped-css/enforce-style-type -->
<style>
html {
  overflow-y: auto;
  background-color: rgb(var(--v-theme-background));
  color: rgb(var(--v-theme-on-surface));
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(var(--v-theme-background-darken-2));
  border-radius: 6px;
  min-height: 60px;
}

::-webkit-scrollbar-track {
  width: 10px;
  background: none;
}

.v-btn--icon {
  border-radius: 20%;
}

.btn-link {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}

.btn-link:hover {
  text-decoration: underline;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fill {
  height: 100%;
  width: 100%;
}

.fill-width {
  width: 100%;
}

.fill-height {
  height: 100%;
}

.fill-vw {
  width: 100vw;
}

.ghostly {
  opacity: 0.6;
}
</style>

<style scoped>
.main {
  height: 100vh;
  background-color: rgb(var(--v-theme-background));
}
</style>
