import { cloneDeep } from 'lodash';
import { reactive } from 'vue';

import { type LegalCase } from '@/case-list/services/legalcase.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import preferencesService from '@/common/services/preferences.service';
import { API } from '@/common/types/api.types';

interface ServiceState {
  sorting: API.LegalCase.ListSorting;
}

const initialState: ServiceState = {
  sorting: 'TITLE_ASC',
};

interface SortMode {
  icon: string;
  sortFn: (a: LegalCase, b: LegalCase) => number;
}

const sortModes: Record<API.LegalCase.ListSorting, SortMode> = {
  TITLE_ASC: {
    icon: 'mdi-sort-alphabetical-ascending',
    sortFn: (a: LegalCase, b: LegalCase) => {
      if (a.displayLabel === '' && b.displayLabel === '') {
        return 0;
      }
      if (a.displayLabel === '') {
        return 1;
      }
      if (b.displayLabel === '') {
        return -1;
      }
      return a.displayLabel.localeCompare(b.displayLabel);
    },
  },
  LAST_UPDATE_DESC: {
    icon: 'mdi-sort-clock-descending',
    sortFn: (a: LegalCase, b: LegalCase) => {
      if (a.updated && b.updated) {
        return b.updated.localeCompare(a.updated);
      }
      if (!a.updated && b.updated) {
        return 1;
      }
      if (a.updated && !b.updated) {
        return -1;
      }
      return 0;
    },
  },
};

class LegalCaseSortService {
  state: ServiceState;

  sortModes = sortModes;

  constructor() {
    this.state = reactive(cloneDeep(initialState));
    preferencesService.runAfterLoad(() => this.loadSavedSorting());
  }

  loadSavedSorting() {
    this.state.sorting = preferencesService.state.caseListPreferences?.caseListSortMode as API.LegalCase.ListSorting;
  }

  getSorting() {
    return this.state.sorting;
  }

  setSorting(value: API.LegalCase.ListSorting) {
    this.state.sorting = value;
    preferencesService.updatePreferences({ caseListPreferences: { caseListSortMode: value } });
    broadcastEventBus.emit('LEGALCASE_SORT_CHANGE_EVENT', {});
  }

  getSortedCases(cases: LegalCase[]) {
    return cases.toSorted((a, b) => this.sortFn(a, b));
  }

  sortFn(a: LegalCase, b: LegalCase) {
    const { sortFn } = sortModes[this.state.sorting];
    return sortFn(a, b);
  }
}

export default new LegalCaseSortService();
export const LegalCaseServiceClass = LegalCaseSortService;
