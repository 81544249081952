import { ISODateString, ISODateTimeString, ObjectValues, UUID } from '@/common/types/common.types';

// SourceFileSearchResult.java
export interface SourceFileSearchResult {
  sourceFilePage: number;
  count: number;
  keywords: string[];
}

// RenderAnnotation.java
export interface RenderAnnotation {
  parentId: string;
  annotationKey: string;
  author: string;
  color: string;
  contents: string;
  coords: string | null;
  opacity: number;
  page: number;
  rect: string | null;
}

// DocumentAnnotation.java
export interface Annotation {
  id: string;
  annotationKey: string;
  author: string;
  updated: string;
  renderAnnotation: RenderAnnotation;
}

// DocumentDiagnosis.java
export interface Diagnosis {
  id: string;
  icd10Code: string;
  tags: string[];
  debugInfo: string;
  renderAnnotation: RenderAnnotation;
}

// DocumentMetadataEO.java -> public enum Key
export const METADATA_KEY = {
  TITLE: 'TITLE',
  ALT_TITLE: 'ALT_TITLE',
  REF: 'REF',
  ISSUE_DATE: 'ISSUE_DATE',
  ISSUE_DATE_TIME: 'ISSUE_DATE_TIME',
  RECEIPT_DATE: 'RECEIPT_DATE',
  DOCTYPE: 'DOCTYPE',
  AUTHOR: 'AUTHOR',
  AUTHOR_INSTITUTION: 'AUTHOR_INSTITUTION',
  AUTHOR_DEPARTMENT: 'AUTHOR_DEPARTMENT',
  AUTHOR_SPECIALITY: 'AUTHOR_SPECIALITY',
  AUTHOR_POSITION: 'AUTHOR_POSITION',
  RECIPIENT: 'RECIPIENT',
  RECIPIENT_INSTITUTION: 'RECIPIENT_INSTITUTION',
  LANGUAGE: 'LANGUAGE',
  FOLDER: 'FOLDER',
  SUMMARY: 'SUMMARY',
  SUMMARY_ENTITIES: 'SUMMARY_ENTITIES',
  SUMMARY_CASEHISTORY: 'SUMMARY_CASEHISTORY',
  PAGE_COUNT: 'PAGE_COUNT',
  // NOTE(dp): and these items don't exist in DOC_METADATA.json:
  PDF_ROTATION: 'PDF_ROTATION',
  IMPORTANT: 'IMPORTANT',
  WORK_INABILITY: 'WORK_INABILITY',
  QUALITY: 'QUALITY',
  FORMS_EXTRACTION: 'FORMS_EXTRACTION',
  PII: 'PII',
  EXPORT_PAGINATION_NO: 'EXPORT_PAGINATION_NO',
  EXPORT_PAGINATION_ID: 'EXPORT_PAGINATION_ID',
} as const;
export type MetadataKey = ObjectValues<typeof METADATA_KEY>;

export const STATUS = {
  ACTIVE: 'ACTIVE',
  DELETED_BY_AGENT: 'DELETED_BY_AGENT',
  DELETED_BY_USER: 'DELETED_BY_USER',
} as const;
type Status = ObjectValues<typeof STATUS>;

// NOTE(dp): derived from usage
interface Candidate {
  normalized_date: ISODateString;
}

export interface Metadata {
  key: string;
  value: string;
  extractor?: string;
  author?: string;
  updated?: ISODateTimeString;

  candidates?: Candidate[];
  formData?: Record<string, any>;
}

// DocumentResponse.java
export interface Response {
  id: string;
  tenantId: UUID;
  caseId: UUID;

  sourceFileId: UUID;
  sourceFileUploaded: ISODateTimeString;
  sourceFileUploadFilename: string;

  sourceFilePage: number;
  sourceFileEndPage: number;
  pageCount: number;

  fileUri: string;
  smallThumbnailUri: string;
  largeThumbnailUri: string;

  folder: string;
  status: Status;

  duplicates: Response[];
  duplicateCandidates: Response[];

  diagnoses: Diagnosis[];

  userAnnotations: Annotation[];
  integrationUserAnnotationsXfdf: string;
  labels: string[];

  metadata: Record<MetadataKey, Metadata>;
}
