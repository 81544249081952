import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import logger from '@/common/services/logging';

export const mergeIntoReactive = (target: any, source: any) => {
  for (const key in source) {
    if (Object.hasOwn(source, key)) {
      target[key] = source[key];
    } else {
      logger.warn(`Key ${key} doesn't exist in reactive object.`);
    }
  }
};

// helper for convenient Promise unwrap
export const safeUnwrap = async <T>(promise: Promise<T>): Promise<[Error | null, T | null]> => {
  try {
    const data = await promise;
    return [null, data];
  } catch (error: any) {
    return [error, null];
  }
};

// clean object from false-ish prop values
export const withoutInvalidProps = (obj: Record<string, any>) => Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v));

// copy to clipboard, including rich text
interface CopyOptions {
  type: string;
  showToast: boolean;
  successMessage: string;
  errorMessage: string;
}

const getPlainContent = (htmlContent: string) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;
  return tempElement.textContent || tempElement.innerText || '';
};

export const copyToClipboard = async (content: any, optionalOptions?: Partial<CopyOptions>) => {
  const defaultOptions: CopyOptions = {
    type: 'text/plain',
    showToast: true,
    successMessage: $t('Common.CopyableText.copiedToClipboard'),
    errorMessage: $t('Common.CopyableText.failedToCopyToClipboard'),
  };
  const options = Object.assign(defaultOptions, optionalOptions);
  const plainContent = options.type === 'text/plain' ? content : getPlainContent(content);

  const data = [
    new ClipboardItem({
      // NOTE: always create `text/plain` backup for the clipboard and apps, where `text/html` not supported
      'text/plain': new Blob([plainContent], { type: 'text/plain' }),
      [options.type]: new Blob([content], { type: options.type }),
    }),
  ];
  const result = navigator.clipboard.write(data);

  if (options.showToast) {
    return result.then(
      () => {
        appService.info(options.successMessage);
      },
      () => {
        appService.info(options.errorMessage);
      },
    );
  }

  return result;
};
