import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { App } from 'vue';
import { RouteRecordName } from 'vue-router';

import { config } from '@/app/config';
import router from '@/app/router';
import detailViewService from '@/case-detail/services/detail.view.service';
import logger from '@/common/services/logging';

type StringAnalyticsEvent =
  | `PAGE_${string}`
  | `CASE_PANEL_OPEN_${string}`
  | `CASE_PANEL_CLOSE_${string}`
  | `DOC_META_EDIT_${string}`
  | `${string}_DETACH`;

enum AnalyticsEventEnum {
  // Application
  APP_LOGIN,
  APP_LOGOUT,
  APP_ERROR,
  APP_EXIT,
  APP_NOTIFICATION_PANEL_OPEN,
  APP_NOTIFICATION_OPEN,
  APP_NOTIFICATION_READ,
  APP_NOTIFICATION_READ_ALL,
  APP_WORKSPACE_SWITCH,
  APP_HELP,

  // Case List
  CASELIST_ADD,
  CASELIST_EDIT,
  CASELIST_DELETE,
  CASELIST_ARCHIVE,
  CASELIST_UNARCHIVE,
  CASELIST_PIN,
  CASELIST_SEARCH,
  CASELIST_SEARCH_CLEAR,
  CASELIST_FILTER,
  CASELIST_FILTER_CLEAR,
  CASELIST_REFRESH,
  CASELIST_SORT,
  CASELIST_EXPORT,
  CASELIST_AUDIT,
  CASELIST_DATALABELING,

  // FileManager
  FILEMANAGER_OPEN,
  FILEMANAGER_CLOSE,
  FILEMANAGER_REFRESH,
  FILEMANAGER_FILTER,
  FILEMANAGER_ADD,
  FILEMANAGER_MOVE,
  FILEMANAGER_DOWNLOAD,
  FILEMANAGER_AUDIT,
  FILEMANAGER_PIPELINE,
  FILEMANAGER_DELETE,
  FILEMANAGER_SEGMENTATION,

  // Case Detail
  CASE_OPEN,
  CASE_CLOSE,
  CASE_PIIQUICKACCESS_OPEN,
  CASE_PIIQUICKACCESS_COPY,
  CASE_ACCESS_OPEN,
  CASE_ACCESS_CHANGE,
  CASE_FILEMANAGER_OPEN,
  CASE_FILEMANAGER_CLOSE,
  CASE_ARCHIVE,

  // Label panel and custom label system
  LABEL_SEARCH,
  LABEL_FOLDERS_EXPAND,
  LABEL_CUSTOM_ADD,
  LABEL_CUSTOM_DELETE,
  LABEL_CUSTOM_ASSIGN,

  // diagnosis
  DIAGNOSIS_SEARCH,
  DIAGNOSIS_SORT,
  DIAGNOSIS_FILTER,
  DIAGNOSIS_OVERVIEW,
  DIAGNOSIS_OVERVIEW_FILTER,

  // authors
  AUTHOR_SEARCH,
  AUTHOR_SORT,

  // workinability
  WORKINABILITY_DELETE,
  WORKINABILITY_EDIT,
  WORKINABILITY_SEARCH,

  // Copilot
  COPILOT_QUESTION,
  COPILOT_ANSWER_TWEAK,
  COPILOT_CLEAR,
  COPILOT_CHAT_ANSWER_FAIL, // 'cannot answer that' answer
  COPILOT_DETACH,
  COPILOT_ANSWER_FEEDBACK,

  // Case Overview / Dashboard
  CASE_OVERVIEW_DASHBOARD_OPEN,
  CASE_OVERVIEW_ANSWER_QUESTION_CLICK,
  CASE_OVERVIEW_TOC_SHOW,
  CASE_OVERVIEW_TOC_HIDE,
  CASE_OVERVIEW_TOC_ITEM_CLICK,
  CASE_OVERVIEW_QUESTION_EXPAND,

  // Document list
  DOCLIST_SHOW,
  DOCLIST_PREV,
  DOCLIST_NEXT,
  DOCLIST_SEARCH,
  DOCLIST_FILTER,
  DOCLIST_SORT,
  DOCLIST_MODE_CHANGE,
  DOCLIST_READ_UNREAD,
  DOCLIST_READ_UNREAD_ALL,
  DOCLIST_FILTER_CLEAR,
  DOCLIST_SELECTION,
  DOCLIST_DUPLICATES_SELECT,
  DOCLIST_COMPARE,
  DOCLIST_SUMMARY_COPY,
  // three dot
  DOCLIST_MENU_LABELS_SELECT,
  DOCLIST_MENU_METADATA_FIELDS,
  DOCLIST_MENU_MERGE,
  DOCLIST_MENU_DUPLICATE_SELECT,
  DOCLIST_MENU_PIPELINE_ACTION,
  // document badges
  DOCLIST_BADGE_DUPLICATE_VIEW,
  DOCLIST_BADGE_DIAGNOSE_JUMP,
  DOCLIST_BADGE_SEARCH_JUMP,
  DOCLIST_BADGE_ANNOTATION_JUMP,

  // Document
  DOC_CHANGE_VIEW,
  DOC_PRINT,
  DOC_SPLIT,
  DOC_READ,
  DOC_UNREAD,
  DOC_COMMENT_FREE,
  DOC_DOWNLOAD,
  DOC_ROTATE,
  DOC_CHANGE_PAGE,
  DOC_CLICK,
  DOC_SEARCH,
  // viewer text popup
  DOC_COPY,
  DOC_COPYLINK,
  DOC_ANNOTATION_ADD,
  DOC_ANNOTATION_EDIT,
  DOC_ANNOTATION_DELETE,
  DOC_COMMENT,

  // duplicates
  DUPLICATE_REVIEW_DELETE,
  DUPLICATE_REVIEW_MARK,
  DUPLICATE_REVIEW_COMPARE,
  DUPLICATE_REVIEW_ORIGINAL,

  // Export
  EXPORT_ADD,
  EXPORT_ADD_UPDATE,
  EXPORT_EDIT,
  EXPORT_VIEW,
  EXPORT_VIEW_TOC,
  EXPORT_DOWNLOAD,
  EXPORT_DELETE,
  EXPORT_SHARE_DIALOG_OPEN,
  EXPORT_SHARE_DIALOG_CLOSE,
  EXPORT_SHARE_LINK,
  EXPORT_SHARE_LINK_DELETE,
  EXPORT_SHARE_EMAIL,
  EXPORT_CONFIG_OPEN,
  EXPORT_DOCS_ADD_DND,
  EXPORT_DOCS_ADD_ALL,
  EXPORT_DOCS_SORT_DND,
  EXPORT_DOCS_SORT_MODE,
  EXPORT_DOCS_FILTER,
  EXPORT_DOCS_CLEAR,
  EXPORT_DOCS_DELETE,
  EXPORT_DOCS_HEADING,
  EXPORT_PREVIEW,
  EXPORT_PUBLISH,
  EXPORT_BACK,
  // sharing view
  SHARING_DOWNLOAD,

  // Notebook
  NOTEBOOK_SAVE,
  NOTEBOOK_DETACH,
  NOTEBOOK_EDIT,
  NOTEBOOK_CREATE,
  NOTEBOOK_DELETE,
  NOTEBOOK_LINK_CLICK,
  NOTEBOOK_PRINT,

  // Collaboration
  COLLABORATION_ACCESS_GRANT,
  COLLABORATION_ACCESS_DELEGAT,
  COLLABORATION_ACCESS_REVOKE,

  COLLABORATION_TICKET_CREATE,
  COLLABORATION_TICKET_RECPIENT_ASSIGN,
  COLLABORATION_TICKET_COORDINATOR_ASSIGN,

  COLLABORATION_TICKET_ANSWER_EDIT,
  COLLABORATION_TICKET_ANSWER_SUBMIT,
  COLLABORATION_TICKET_ANSWER_REVIEW,

  COLLABORATION_TICKET_SUBJECT_EDIT,
  COLLABORATION_TICKET_QUESTION_EDIT,
  COLLABORATION_TICKET_PRIORITY_EDIT,

  COLLABORATION_TICKET_REOPEN,
  COLLABORATION_TICKET_DELETE,
  COLLABORATION_TICKET_ARCHIVE,
  COLLABORATION_TICKET_CANCEL,

  COLLABORATION_TICKET_CORRESPONDENCE_MESSAGE_ADD,
  COLLABORATION_TICKET_CORRESPONDENCE_MESSAGE_EDIT,
  COLLABORATION_TICKET_CORRESPONDENCE_MESSAGE_DELETE,

  COLLABORATION_TICKET_PRIVATE_MESSAGE_ADD,
  COLLABORATION_TICKET_PRIVATE_MESSAGE_EDIT,
  COLLABORATION_TICKET_PRIVATE_MESSAGE_DELETE,

  COLLABORATION_TICKET_ATTACHMENT_ADD,
  COLLABORATION_TICKET_ATTACHMENT_DELETE,

  COLLABORATION_TICKET_DETACH,
  COLLABORATION_TICKET_FIELD_LINK_CLICK,
  COLLABORATION_TICKET_MESSAGE_LINK_CLICK,
}

type AnalyticsEvent = StringAnalyticsEvent | AnalyticsEventEnum;

interface AuthUserProperties {
  $name: string;
  $email: string;
  role: string;
  tenantId: string;
  tenantName: string;
  tenantCanonicalName: string;
  userDetailsProfession: string;
  userDetailsLocale: string;
  userDetailsBillingStatus: string;
}

// maybe rename to mixpanel something?
class Analytics {
  public e = AnalyticsEventEnum;

  private authUserProperties: AuthUserProperties | null = null;

  private tDebounced = debounce((ev: AnalyticsEvent, data: any = {}) => this.l(ev, data), 1000);

  public setAuthUserProps(props: AuthUserProperties): void {
    this.authUserProperties = props;
  }

  public getRouteKey(targetRoute: RouteRecordName | null = null): string {
    const routeName: string = targetRoute?.toString() ?? router.currentRoute.value.name?.toString() ?? '';
    return routeName.replace(/-/g, '_').toUpperCase();
  }

  public l(ev: AnalyticsEvent, data: any = {}): void {
    // accept string events as they are but cast enum values to their names
    const eventName = typeof ev === 'number' ? AnalyticsEventEnum[ev] : ev;
    this.track(eventName, data);
  }

  public dl(ev: AnalyticsEvent, data: any = {}): void {
    this.tDebounced(ev, data);
  }

  private track(ev: string, data: any = {}): void {
    const routeKey = this.getRouteKey();
    // for case detail, add panel state, convert the dict with key:boolean to a list of uppercase keys

    let openPanels: string[] = [];
    let openView = '';
    if (routeKey === 'CASE') {
      openPanels = detailViewService.getOpenPanels().map((p) => p.toUpperCase());
      openView = detailViewService.getOpenView();
    }
    const feature = ev.split('_')[0];
    const payload = {
      page: this.getRouteKey(),
      ...this.authUserProperties,
      ...data,
      panels: openPanels,
      view: openView,
      legalCaseId: detailViewService.getCurrentLegalCaseId(),
      feature,
    };

    logger.info(`[mixpanel]: ${ev}\n${JSON.stringify(data)}`);

    if (!config.MIXPANEL.ENABLED) {
      logger.info(`[mixpanel] payload: ${JSON.stringify(payload)}`);
      return;
    }

    queueMicrotask(() => {
      mixpanel.track(ev, payload);
    });
  }
}

const analyticsInstance = new Analytics();
export default analyticsInstance;

export const vueAnalyticsPlugin = {
  install: (app: App) => {
    app.config.globalProperties.$a = analyticsInstance;
  },
};
declare module 'vue' {
  interface ComponentCustomProperties {
    $a: Analytics;
  }
}
