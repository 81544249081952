import { IntlNumberFormats } from 'vue-i18n';

import { $t, i18nService } from '@/app/i18n/i18n.service';

export const SUPPORTED_LOCALES = ['de-CH', 'de-DE', 'de-AT', 'en-US', 'fr-CH', 'it-CH'] as const;

const defaultCountries: Record<string, string> = {
  de: 'CH',
  en: 'US',
  fr: 'CH',
  it: 'CH',
};

const defaultLanguages: Record<string, string> = {
  AT: 'de',
  CH: 'de',
  DE: 'de',
  US: 'en',
};

export const numberFormats = {
  'de-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      notation: 'standard',
      currencyDisplay: 'symbol',
      useGrouping: true,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    },
    percent: {
      style: 'percent',
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
      currencyDisplay: 'symbol',
      useGrouping: true,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    },
    percent: {
      style: 'percent',
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      notation: 'standard',
      useGrouping: true,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
} as IntlNumberFormats;

export class Locale {
  public language: string; // 2 lowercase letters

  public country: string; // 2 uppercase letters

  static default = new Locale('de', 'CH');

  constructor(language: string, country?: string) {
    this.language = language;

    if (country) {
      this.country = country;
    } else if (defaultCountries[language]) {
      this.country = defaultCountries[language];
    } else {
      this.country = 'CH';
    }
  }

  toString(divider = '-') {
    return `${this.language}${divider}${this.country}`;
  }

  getEnglishName(languageOnly = false) {
    return this.getName(new Locale('en'), languageOnly);
  }

  getNativeName(languageOnly = false) {
    return this.getName(i18nService.state.locale, languageOnly);
  }

  getName(locale: Locale, languageOnly = false): string {
    const language = $t(`Common.Language.${this.language}`, locale.toString());
    const country = $t(`Common.Country.${this.country.toLowerCase()}`, locale.toString());
    if (languageOnly) {
      return language;
    }
    return `${language} (${country})`;
  }

  closestSupported() {
    // supported, just return
    if (SUPPORTED_LOCALES.includes(this.toString() as any)) {
      return this;
    }

    // if only language is supported, set default country for that language
    if (defaultCountries[this.language]) {
      return new Locale(this.language, defaultCountries[this.language]);
    }

    // if only country is supported, set default language for that country
    if (defaultLanguages[this.country]) {
      return new Locale(defaultLanguages[this.country], this.country);
    }

    return Locale.default;
  }

  static fromString(locale: string) {
    try {
      // backward compatibility with legacy _ format
      const [language, country] = locale.includes('-') ? locale.split('-') : locale.split('_');
      return new Locale(language, country);
    } catch (e) {
      return Locale.default;
    }
  }

  static fromBrowser() {
    const browserLocale = navigator.language;

    const language = browserLocale.slice(0, 2).toLowerCase();
    const country = browserLocale.includes('-') ? browserLocale.split('-')[1].toUpperCase() : '';

    return new Locale(language, country);
  }
}
