<template>
  <v-form class="fill-width">
    <div class="py-0 d-flex align-start">
      <FileInput
        class="flex-grow-1 mr-2"
        :new-source-file="newSourceFile"
        @uploading="handleUploading"
        @error="(value: FileInfo[]) => (errorFiles = value)"
      />
      <div style="width: 308px">
        <v-autocomplete
          v-model="newSourceFile.folder"
          class="px-2"
          :items="folders"
          :label="$t('Common.docFolder')"
          data-testid="input_fileType"
          :no-data-text="$t('Common.notFound')"
          @update:focused="onFolderInputFocusUpdate"
        />
      </div>
      <v-btn
        v-if="showAddButton"
        :disabled="uploadInProgress"
        class="mr-auto ml-2 mb-5"
        color="primary"
        data-testid="btn_upload"
        variant="flat"
        @click="$emit('save')"
      >
        <v-icon class="mr-1"> mdi-cloud-upload-outline </v-icon>
        {{ $t('Common.add') }}
      </v-btn>
    </div>
  </v-form>
  <v-alert
    v-if="pageNumErrorFiles.length > 0"
    class="mt-2"
    color="warning"
    icon="$warning"
    cloasable
    :title="$t('CaseList.AddEditCase.toManyPagesTitle')"
  >
    <template #close>
      <v-icon @click="errorFiles = []">mdi-close</v-icon>
    </template>
    <i18n-t keypath="CaseList.AddEditCase.errorFiles">
      <span class="font-italic">{{ errorFiles.map((file: FileInfo) => file.name).join(', ') }}</span>
      <span class="font-weight-bold">{{ PDF_PAGE_LIMIT }} </span>
      <a class="font-weight-bold" :href="manualLink" target="_blank">
        <br />
        {{ $t('CaseList.AddEditCase.clickHere') }}
      </a>
    </i18n-t>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import FileInput, { FileInfo, PDF_PAGE_LIMIT } from '@/case-list/components/FileInput.vue';
import { authService } from '@/common/services/auth/auth.service';
import { folderService } from '@/common/services/folder.service';
import { OTHER_FOLDER_KEY } from '@/common/services/folder.utils';

interface NewSourceFile {
  originalFileUris: string[];
  uploadFilenames: string[];
  folder: string;
  settings: Record<string, unknown>;
}

export default defineComponent({
  components: {
    FileInput,
  },

  props: {
    showAddButton: {
      type: Boolean,
      default: true,
    },
    newSourceFile: {
      type: Object as PropType<NewSourceFile>,
      required: true,
    },
  },

  emits: ['save', 'uploading'],

  data() {
    return {
      uploading: false,
      errorFiles: [] as FileInfo[],
      PDF_PAGE_LIMIT,
    };
  },

  computed: {
    folders() {
      return folderService.getLocalizedFolders();
    },
    uploadInProgress() {
      return this.uploading;
    },
    pageNumErrorFiles(): FileInfo[] {
      return this.errorFiles.filter((file: FileInfo) => file.numPages > this.PDF_PAGE_LIMIT);
    },
    manualLink() {
      if (authService.state.data) {
        if (authService.state.data.userDetails.locale === 'de-CH') {
          return 'https://help.legal-i.ch/de/articles/10256145-pdfs-aufteilen';
        }
      }
      return 'https://help.legal-i.ch/en/articles/10256145-splitting-pdfs';
    },
  },

  methods: {
    handleUploading(flag: boolean) {
      this.uploading = flag;
      this.$emit('uploading', this.uploading);
    },
    onFolderInputFocusUpdate(focused: boolean) {
      if (focused) {
        this.newSourceFile.folder = '';
      } else if (!this.newSourceFile.folder) {
        this.newSourceFile.folder = OTHER_FOLDER_KEY;
      }
    },
  },
});
</script>

<style scoped></style>
