import COPILOT_FORMAT_TWEAKS from '@/common/entities/COPILOT_FORMAT_TWEAKS.json';
import { CopilotCatalogQuestionLocalized, CopilotCatalogQuestionRaw, UserProfession } from '@/common/services/entity.service';
import { MultilingualText, ObjectValues } from '@/common/types/common.types';

export type Role = 'USER' | 'ASSISTANT';

export type AnswerTweak = keyof typeof COPILOT_FORMAT_TWEAKS;

export type Scope = 'LEGALCASE' | 'DOCUMENT' | 'DASHBOARD';

export type ContextStrategy = 'NONE' | 'PLAN' | 'DOCUMENT' | 'DOCUMENT_PAGE' | 'LEGALCASE' | 'PLAN_DOCUMENTS';

export interface ContextStrategyDescription {
  key?: ContextStrategy;
  description?: string;
  contextSourcePlaceholder?: string;
  promptDescription?: string;
}

export const agents = {
  PLAN: 'PLAN',
  GENERAL: 'GENERAL',
  MEDICAL: 'MEDICAL',
  EXPLAINING: 'EXPLAINING',
} as const;
export type Agent = ObjectValues<typeof agents>;

export const multipleAnswers = {
  SINGLE: 'SINGLE',
  BIASED: 'BIASED',
  PLAN: 'PLAN',
} as const;
export type MultipleAnswers = ObjectValues<typeof multipleAnswers>;

export interface Debugger {
  decisionLog?: string;
  systemPrompt?: string;
  prompt?: string;
  context?: string;
  answer?: string;
  totalPromptTokens?: number;
  totalCompletionTokens?: number;
  contextPlanDocumentIds?: string[];
}

export interface EntityExtractionResponse {
  patient: string[];
  incident: string[];
  professional: string[];
  medical: string[];
  financial: string[];
  legal: string[];
  drugs: string[];
}

export interface FollowUpQuestion {
  followup: string;
  preview: string;
}

export interface PlausibilityIssue {
  text: string;
  reason: string;
  type: string;
}

export interface ReferenceBO {
  documentId: string;
  /** @format int32 */
  page?: number;
}

export type PlausibilityState = 'VERIFIED' | 'VERIFICATION_FAILED' | 'REVIEW' | 'NOT_APPLICABLE';

export interface PlausibilityResult {
  sourcesState: PlausibilityState;
  assessmentsState: PlausibilityState;
  references: ReferenceBO[];
  sourcesIssues: PlausibilityIssue[];
  assessmentsIssues: PlausibilityIssue[];
}

export interface CopilotAnswer {
  text: string;
  answerEntities?: EntityExtractionResponse;
  followUpQuestions?: FollowUpQuestion[];
  plausibilityResult?: PlausibilityResult;
  bias?: string;
  debugger?: Debugger;
  title: string;
  success: boolean;
  errorMessage?: string;
}

export interface CopilotQuestion {
  catalogKey?: string;
  prompt: string;
  displayPrompt?: string;
  quote?: string;
  contextStrategy: ContextStrategy;
  contextSource?: string;
  contextHint?: string;
  agent: Agent;
  tweaks?: AnswerTweak[];
  targetLanguage?: string;
  profession?: UserProfession;
  multipleAnswers: MultipleAnswers;
  checkPlausibility: boolean;
  addReferences: boolean;
}

export type CopilotQA = CopilotAnswer | CopilotQuestion;

export interface CopilotConversationEntry {
  role: Role;
  userId?: string;
  /** @format date-time */
  timestamp: string;
  selectedQAIndex: number;
  qa: CopilotQA[];
}

export interface CopilotRequest {
  /** @format uuid */
  requestId: string;
  /** @format uuid */
  historyId?: string;
  question: CopilotQuestion;
  conversationItems: CopilotConversationEntry[];
}

export interface CopilotResponse {
  /** @format uuid */
  requestId: string;
  /** @format uuid */
  historyId: string;
  conversationItems: CopilotConversationEntry[];
  updatedAnswer: string;
  updatedAnswerUserId: string;
  updatedAnswerTimestamp: string;
}

export interface CopilotCatalogQuestionHistoryResponse {
  userId: string;
  /** @format date-time */
  timestamp: string;
  outdated: boolean;
  copilotResponse: CopilotResponse;
  success: boolean;
  errorMessage?: string;
}

export interface CopilotHistoryResponse {
  /** @format uuid */
  id: string;
  title: string;
  userId: string;
  /** @format date-time */
  timestamp: string;
}

export type UserLanguage = {
  value: string;
  title: string;
};

// dashboards
export type CopilotDashboardHeading = {
  headingText: MultilingualText;
};

export type CopilotDashboardItem = CopilotDashboardHeading | CopilotCatalogQuestionRaw | CopilotCatalogQuestionLocalized;

export interface CopilotDashboard {
  key: string;
  icon: string;
  title: {
    [key: string]: string;
  };
  items: CopilotDashboardItem[];
}

export type CopilotDashboardHeadingLocalized = {
  headingText: string;
};

export type CopilotDashboardItemLocalized = CopilotDashboardHeadingLocalized | CopilotCatalogQuestionLocalized;

export interface CopilotDashboardLocalized {
  key: string;
  icon: string;
  title: string;
  items: CopilotDashboardItemLocalized[];
}
