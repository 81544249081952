<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable>
    <v-card data-testid="modal_fileManager">
      <v-card-item class="pl-5 py-2 pr-2">
        <!-- NOTE(mba): not sure why overflow-x is needed -->
        <v-card-title class="d-flex align-center">
          <span class="text-truncate">
            <span>{{ $t('CaseList.FileManager.modalTitle', [legalCase.displayLabel]) }}</span>
          </span>
          <v-spacer />
          <span v-if="$isLegaliAdmin()" class="text-caption text-medium-emphasis">{{ legalCase.id }}</span>
          <v-btn v-blur class="ml-2" density="comfortable" icon="mdi-close" variant="text" data-testid="fileManager_close" @click="close" />
        </v-card-title>
      </v-card-item>
      <v-divider />
      <v-toolbar class="pl-5 pr-0 py-2" density="compact" flat color="transparent">
        <div style="width: 300px">
          <v-text-field
            v-model="filterString"
            density="compact"
            prepend-inner-icon="mdi-magnify"
            clearable
            hide-details
            variant="outlined"
            :placeholder="$t('CaseList.FileManager.searchFile')"
            @keydown.stop
          />
        </div>
        <v-spacer />

        <span class="text-body-2 mr-3">
          {{ $tc('CaseList.FileManager.filesCount', sourceFiles.length, { n: $n(sourceFiles.length) }) }},
          {{ $tc('Common.documentsCount', countDocumentFiles, { n: $n(countDocumentFiles) }) }}
        </span>
        <template v-if="$isLegaliAdmin()">
          <l-menu location="bottom end">
            <template #activator="{ props: menuProps }">
              <v-btn v-tippy="'Choose download'" icon="mdi-download" density="comfortable" variant="text" v-bind="menuProps" />
            </template>
            <v-list density="compact" class="pa-0">
              <v-list-subheader>{{ $t('CaseList.FileManager.downloadFile') }}</v-list-subheader>
              <v-list-item @click="download('SOURCEFILES', 'SOURCE_PDF')">
                <v-list-item-title>Original</v-list-item-title>
              </v-list-item>
              <v-list-item @click="download('SOURCEFILES', 'OCR_PDF')">
                <v-list-item-title>OCR</v-list-item-title>
              </v-list-item>
              <v-list-item @click="download('SOURCEFILES', 'TEXT_JSON')">
                <v-list-item-title>Text JSON</v-list-item-title>
              </v-list-item>
              <v-list-item @click="download('DOCUMENTS')">
                <v-list-item-title>Documents</v-list-item-title>
              </v-list-item>
              <v-list-item @click="download('THUMBS')">
                <v-list-item-title>Thumbs</v-list-item-title>
              </v-list-item>
            </v-list>
          </l-menu>
          <l-menu location="bottom end">
            <template #activator="{ props: menuProps }">
              <v-btn
                v-tippy="$t('CaseList.FileManager.pipelineTasks')"
                icon="mdi-pipe-valve"
                density="comfortable"
                variant="text"
                v-bind="menuProps"
              />
            </template>
            <v-list density="compact" class="pa-0">
              <v-list-subheader>Start Task</v-list-subheader>
              <v-list-item @click="requestBulkTask('OCR')">
                <v-list-item-title>OCR</v-list-item-title>
              </v-list-item>
              <v-list-item @click="requestBulkTask('SEGMENTATION')">
                <v-list-item-title>SEGMENTATION</v-list-item-title>
              </v-list-item>
              <v-list-item @click="requestBulkTask('SPLITTING')">
                <v-list-item-title>SPLITTING</v-list-item-title>
              </v-list-item>
              <v-list-item @click="requestBulkTask('INDEXING')">
                <v-list-item-title>INDEXING</v-list-item-title>
              </v-list-item>
              <v-list-item @click="requestBulkTask('EXTRACTION')">
                <v-list-item-title>EXTRACTION</v-list-item-title>
              </v-list-item>
              <v-list-item @click="requestBulkTask('EXTRACTION', true)">
                <v-list-item-title>EXTRACTION (force all)</v-list-item-title>
                <v-list-item-subtitle>Resets all extractors on the sourcefile</v-list-item-subtitle>
              </v-list-item>
              <v-list-item @click="requestBulkTask('ANALYZING')">
                <v-list-item-title>ANALYZER</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="$hasFeature('ENABLE_MEDINSIGHTS_OVERVIEW') || $hasDashboards()" @click="requestBulkTask('ANSWERING')">
                <v-list-item-title>ANSWER</v-list-item-title>
              </v-list-item>
              <v-list-item @click="requestBulkTask('READY', true)">
                <v-list-item-title>READY</v-list-item-title>
                <v-list-item-subtitle>Forced, take care!</v-list-item-subtitle>
              </v-list-item>
              <v-list-item @click="requestRestartTask()">
                <v-list-item-title>RESTART</v-list-item-title>
                <v-list-item-subtitle>Restarts sourcefiles in their failed state</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </l-menu>
          <v-btn
            v-tippy="'Interaction from legal-i required'"
            icon
            density="comfortable"
            :color="showInteractionRequiredOnly ? 'warning' : ''"
            @click="showInteractionRequiredOnly = !showInteractionRequiredOnly"
          >
            <v-icon>mdi-alert-circle</v-icon>
          </v-btn>

          <v-btn
            v-tippy="'Processing files only'"
            icon
            density="comfortable"
            :color="showProcessingOnly ? 'warning' : ''"
            @click="showProcessingOnly = !showProcessingOnly"
          >
            <v-icon>mdi-pipe</v-icon>
          </v-btn>
          <v-btn v-tippy="$t('Common.refreshList')" icon density="comfortable" @click="loadSourceFiles()">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
      </v-toolbar>
      <v-divider />
      <v-card-text class="d-block py-3">
        <div v-if="loading" class="mx-auto pt-12 loading-spinner">
          <v-progress-circular color="primary" indeterminate />
        </div>
        <div v-else-if="filteredSourceFiles.length === 0">
          {{ $t('CaseList.FileManager.noFilesFound') }}
        </div>
        <div v-else>
          <FileManagerRow
            v-for="sourceFile in filteredSourceFiles"
            :key="sourceFile.id"
            :legal-case="legalCase"
            :source-file="sourceFile"
            data-testid="table_row"
            @show-panel="$emit('show-panel', $event)"
            @refresh="loadSourceFilesDelayed()"
          />
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions v-if="!loading && $hasFeature('ENABLE_MANUAL_CASE_MANAGEMENT')" class="flex-column align-start mx-2">
        <div class="mt-2">
          {{ $t('CaseList.FileManager.addFilesToThisCase') }}
        </div>
        <FileUpload :new-source-file="newSourceFile" @save="save" />
      </v-card-actions>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn color="primary" variant="flat" @click="close">
          {{ $t('Common.done') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent, PropType } from 'vue';

import appService from '@/app/services/app.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import FileManagerRow from '@/case-list/components/FileManagerRow.vue';
import FileUpload from '@/case-list/components/FileUpload.vue';
import { legalCaseService } from '@/case-list/services/legalcase.service';
import { pipelineOrchestratorService } from '@/case-list/services/pipeline.orchestrator.service';
import { sourceFileClient } from '@/common/clients/sourcefile.client';
import fileService from '@/common/services/file.service';
import { OTHER_FOLDER_KEY } from '@/common/services/folder.utils';
import { API } from '@/common/types/api.types';
import { BaseLegalCaseResponse } from '@/common/types/api-types/legalcase.api.types';

export default defineComponent({
  components: {
    FileManagerRow,
    FileUpload,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    legalCase: {
      type: Object as PropType<BaseLegalCaseResponse>,
      required: true,
    },
  },

  emits: ['close', 'show-panel'],

  data() {
    return {
      loading: false,
      sourceFiles: [] as API.SourceFile.Response[],

      filterString: '',
      showInteractionRequiredOnly: false,
      showProcessingOnly: false,

      newSourceFile: {
        legalCaseId: '',
        originalFileUris: [] as string[],
        uploadFilenames: [] as string[],
        folder: OTHER_FOLDER_KEY,
        settings: {},
      },
    };
  },

  computed: {
    filteredSourceFiles() {
      let { sourceFiles } = this;
      if (this.showInteractionRequiredOnly) {
        sourceFiles = sourceFiles.filter((sourceFile) => sourceFile.status === 'ERROR' || sourceFile.status.includes('REVIEW'));
      }
      if (this.showProcessingOnly) {
        sourceFiles = sourceFiles.filter(
          (sourceFile) => sourceFile.status !== 'READY' && sourceFile.status !== 'ERROR' && sourceFile.status !== 'SEGMENTATION_REVIEW',
        );
      }
      if (this.filterString) {
        sourceFiles = sourceFiles.filter(
          (sourceFile) =>
            sourceFile.id.includes(this.filterString) || sourceFile.uploadFilename.toLowerCase().includes(this.filterString.toLowerCase()),
        );
      }

      if (!!this.filterString || this.showInteractionRequiredOnly || this.showProcessingOnly) {
        this.$a.dl(this.$a.e.FILEMANAGER_FILTER, { value: this.filterString });
      }

      return sourceFiles;
    },
    countDocumentFiles() {
      let count = 0;
      this.sourceFiles.forEach((sourceFile) => {
        if (sourceFile.segmentation) {
          count += Object.values(sourceFile.segmentation.segmentation).filter((value) => value).length;
        }
      });
      return count;
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    legalCase: {
      handler() {
        this.loadSourceFiles();
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.filterString = '';
      this.sourceFiles = [];
      this.newSourceFile = {
        legalCaseId: '',
        originalFileUris: [],
        uploadFilenames: [],
        folder: OTHER_FOLDER_KEY,
        settings: {},
      };
      this.loading = false;
      this.$a.l(this.$a.e.FILEMANAGER_CLOSE);
    },
    async save() {
      this.loading = true;
      this.newSourceFile.legalCaseId = this.legalCase.id;

      const msg =
        this.newSourceFile.originalFileUris.length > 1 ? this.$t('CaseList.FileManager.filesAdded') : this.$t('CaseList.FileManager.fileAdded');

      const addToCaseOperation = await legalCaseService.postSourceFiles(this.newSourceFile.legalCaseId, this.newSourceFile);

      if (addToCaseOperation) {
        appService.info(msg);
      }
      this.close();
      this.$a.l(this.$a.e.FILEMANAGER_ADD);
    },
    loadSourceFilesDelayed() {
      setTimeout(this.loadSourceFiles, 500);
    },
    loadSourceFiles() {
      // only show loading on first load
      const firstLoad = this.sourceFiles.length === 0;
      this.loading = !firstLoad;
      if (!firstLoad) {
        this.$a.l(this.$a.e.FILEMANAGER_REFRESH);
      }
      sourceFileClient.getByCaseId(this.legalCase.id).then((response) => {
        this.sourceFiles = response.sort((a, b) => {
          if (a.deleted !== b.deleted) {
            return a.deleted ? 1 : -1;
          }

          return dayjs(a.created).diff(dayjs(b.created));
        });
        this.loading = false;
      });
    },

    download(dir: string, filetype?: string) {
      if (this.sourceFiles.length === 0) {
        return;
      }

      const base = `zip/${this.legalCase.id}/${dir}`;
      const url = filetype ? `${base}?fileType=${filetype}` : base;

      const filename = `${this.legalCase.displayLabel}-${dir}.zip`;

      if (filename && detailViewService.getCurrentLegalCase()) {
        this.$a.l(this.$a.e.FILEMANAGER_DOWNLOAD);
      }

      appService.info(this.$t('Common.File.preparingDownload'));
      fileService.download(url, filename);
    },

    async requestBulkTask(status: API.SourceFile.Status, forced = false) {
      const sourceFileIds = this.sourceFiles.map((s) => s.id);
      await pipelineOrchestratorService.requestBulkTask(sourceFileIds, status, forced);
      this.loadSourceFilesDelayed();
    },

    async requestRestartTask() {
      await pipelineOrchestratorService.requestTaskRestart(this.legalCase.id);
      this.loadSourceFilesDelayed();
    },
  },
});
</script>

<style scoped>
.loading-spinner {
  width: 100px;
  height: 200px;
}
</style>
